@use "global" as g;

.p-corporate-plans {
  padding-bottom: 100px;

  .p-service-single-nav {
    margin: 0;
  }

  &__wrap {
    max-width: 1088px;
    margin: 0 auto;
    padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  }

  &__detail {
    max-width: 1088px;
    width: 100%;
    margin: 0 auto 100px;
    font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
    font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
    font-weight: 400;
    letter-spacing: 0;
    line-height: calc(28 / 14);
    color: #f2eee7;

    @include g.sp {
      margin: 0 auto 100px;
      padding: 0 20px;
    }
  }

  &__contact-button {
    font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
    color: #f2eee7;
    text-align: center;

    &__link {
      color: inherit;
      text-decoration: none;
      display: inline-block;
      border: 1px solid #f2eee7;
      width: clamp(335px, calc(720 / 1366 * 100vw), 720px);
      line-height: 56px;
      text-align: center;

      @include g.sp {
        line-height: 41px;
      }
    }

    &__inner {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    &__arrow {
      display: block;
      transition: all 0.3s;
    }

    &__link:hover &__arrow {
      transform: translateX(10px);
    }
  }
}
