@use "global" as g;

.c-hover-opacity {
  @media (hover: hover) {
    transition: opacity ease 0.25s;
    &:hover {
      opacity: 0.7;
    }
  }
}
