@use "global" as g;

.c-button {
  width: 100%;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: fit-content;
    position: relative;
    text-decoration: none;

    @include g.sp {
      padding: 16px 0 15px;
    }
  }

  &__text {
    @include g.font(
      2rem,
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.2em,
      $lineheight: normal
    );
  }

  &__arrow {
    display: block;
    height: 100%;
    line-height: 1;
    transition: transform ease 0.25s;
    transform: translateY(1px);
  }
}

// hover
@media (hover: hover) {
  .c-button__link:hover {
    .c-button__arrow {
      transform: translateX(10px);
    }
  }
}
