@charset "UTF-8";
/*--------------------
breakpoint
--------------------*/
.sp-only {
  display: inline;
}
@media (min-width: 1024px) {
  .sp-only {
    display: none;
    width: 100px;
  }
}

.pc-only {
  display: inline;
}
@media (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

/*--------------------
font
--------------------*/
/*--------------------
style
--------------------*/
/*--------------------
hover
--------------------*/
.c-breadcrumb {
  position: absolute;
  top: 80px;
  left: calc(100% - 44px);
  rotate: 90deg;
  width: calc(100vh - 120px);
  transform-origin: 0 0;
  text-transform: capitalize;
}
@media (max-width: 768px) {
  .c-breadcrumb {
    left: calc(100% - 20px);
  }
}
.c-breadcrumb__wrapper {
  display: flex;
  align-items: center;
}
.c-breadcrumb__current {
  width: 80px;
  /* 省略せずに表示するサイズを指定 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-breadcrumb__current, .c-breadcrumb__link {
  margin: 0;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1rem, calc(13 / 1366 * 100vw), 1.3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(16 / 13);
  color: #91908e;
}
.c-breadcrumb__line {
  margin: 0 6px;
  width: 10px;
  height: 1px;
  background: #656058;
}
.c-breadcrumb__link {
  display: inline-block;
  text-decoration: none;
}
.c-breadcrumb__current {
  max-width: 200px;
  width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.c-common-texture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../img/common/common-texture.png");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: 99999;
  pointer-events: none;
}

.c-service-card a {
  color: #f2eee7;
  text-decoration: none;
}
.c-service-card__heading {
  padding-left: 10px;
  margin-bottom: clamp(16px, calc(20 / 1366 * 100vw), 20px);
}
@media (max-width: 768px) {
  .c-service-card__heading {
    display: grid;
    gap: 8px;
  }
}
.c-service-card__number {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}
.c-service-card__title {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: clamp(16px, calc(40 / 1366 * 100vw), 40px);
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}
.c-service-card__arrow {
  transition: transform ease 0.6s;
}
.c-service-card__img {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1.6/1;
  border-radius: 10px;
  overflow: hidden;
}
.c-service-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-service-card__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.48;
}
.c-service-card__img img {
  transition: transform ease 0.6s;
}
@media (hover: hover) {
  .c-service-card:hover .c-service-card__img img {
    transform: scale(1.1);
    transition: transform ease 0.6s;
  }
  .c-service-card:hover .c-service-card__arrow {
    transform: translateX(10px);
    transition: transform ease 0.6s;
  }
}

@media (hover: hover) {
  .c-hover-opacity {
    transition: opacity ease 0.25s;
  }
  .c-hover-opacity:hover {
    opacity: 0.7;
  }
}

.c-border-button {
  width: 100%;
}
.c-border-button__inner {
  border: 1px solid #f2eee7;
}
.c-border-button__inner::before, .c-border-button__inner::after, .c-border-button__inner span::before, .c-border-button__inner span::after {
  background-color: #bfb9ae;
}
.c-border-button__text {
  color: #f2eee7;
}
.c-border-button__link {
  display: block;
  width: 100%;
  position: relative;
  text-decoration: none;
}
.c-border-button__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0 18px;
  /* animations */
}
@media (max-width: 768px) {
  .c-border-button__inner {
    padding: 16px 0 15px;
  }
}
.c-border-button__inner::before {
  content: "";
  position: absolute;
  transition: all 0.1s;
  width: 1px;
  height: 0;
  top: 0;
  left: 0;
  transition-delay: 0.4s;
}
.c-border-button__inner::after {
  content: "";
  position: absolute;
  transition: all 0.1s;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  transition-delay: 0.3s;
}
.c-border-button__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
}
.c-border-button__text::before {
  content: "";
  position: absolute;
  transition: all 0.1s;
  width: 1px;
  height: 0;
  bottom: 0;
  right: 0;
  transition-delay: 0.2s;
}
.c-border-button__text::after {
  content: "";
  position: absolute;
  transition: all 0.1s;
  height: 1px;
  width: 0;
  top: 0;
  right: 0;
  transition-delay: 0.1s;
}
.c-border-button__arrow {
  display: block;
  line-height: 1;
  transition: transform ease 0.25s;
}

@media (hover: hover) {
  .c-border-button__link:hover .c-border-button__inner::before {
    height: 100%;
    transition: all 0.1s;
  }
  .c-border-button__link:hover .c-border-button__inner::after {
    width: 100%;
    transition: all 0.1s;
    transition-delay: 0.1s;
  }
  .c-border-button__link:hover .c-border-button__text::before {
    height: 100%;
    transition: all 0.1s;
    transition-delay: 0.2s;
  }
  .c-border-button__link:hover .c-border-button__text::after {
    width: 100%;
    transition: all 0.1s;
    transition-delay: 0.3s;
  }
  .c-border-button__link:hover .c-border-button__arrow {
    transform: translateX(10px);
  }
}
.c-button {
  width: 100%;
}
.c-button__link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: fit-content;
  position: relative;
  text-decoration: none;
}
@media (max-width: 768px) {
  .c-button__link {
    padding: 16px 0 15px;
  }
}
.c-button__text {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.2em;
  line-height: normal;
  color: #f2eee7;
}
.c-button__arrow {
  display: block;
  height: 100%;
  line-height: 1;
  transition: transform ease 0.25s;
  transform: translateY(1px);
}

@media (hover: hover) {
  .c-button__link:hover .c-button__arrow {
    transform: translateX(10px);
  }
}
.c-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(16 / 1366 * 100vw), 1.6rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(22 / 16);
  color: #bfb9ae;
}
@media (max-width: 768px) {
  .c-pagination {
    justify-content: center;
  }
}
.c-pagination a {
  color: #bfb9ae;
  text-decoration: none;
}
.c-pagination .page-numbers {
  padding: 0 3px;
}
.c-pagination .current {
  color: #f2eee7;
  position: relative;
}
.c-pagination .current::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #f2eee7;
  position: absolute;
  bottom: 0;
  left: 0;
}
.c-pagination .next,
.c-pagination .prev {
  display: none;
}

/*--------------------
サービスブロック
--------------------*/
.l-block--service-default {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
}
.l-block--service-default__wrap {
  position: relative;
  padding-left: clamp(17px, calc(92 / 1366 * 100vw), 92px);
}
@media (max-width: 768px) {
  .l-block--service-default__wrap {
    padding-left: 17px;
  }
}
.l-block--service-default__wrap::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 25px;
  margin: auto;
  width: 1px;
  height: 100%;
  background: #b05e61;
}
@media (max-width: 768px) {
  .l-block--service-default__wrap::after {
    top: 20px;
    left: 0;
  }
}
.l-block--service-default__inner {
  width: 100%;
}
.l-block--service-default__flex {
  display: flex;
  justify-content: space-between;
  gap: clamp(40px, calc(50 / 1366 * 100vw), 50px);
  margin-top: -35px;
}
@media (max-width: 768px) {
  .l-block--service-default__flex {
    flex-direction: column-reverse;
    margin-top: 0px;
    padding-top: 30px;
  }
}
.l-block--service-default__head {
  max-width: 628px;
  width: 100%;
}
.l-block--service-default__title {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-block--service-default__title {
    margin-top: 0;
  }
}
.l-block--service-default__heading {
  margin-top: 130px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-block--service-default__heading {
    margin-top: 0;
  }
}
.l-block--service-default__text {
  margin: 30px 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
}
.l-block--service-default__img {
  max-width: 318px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 1/1.38;
}
.l-block--service-default__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .l-block--service-default__img {
    max-width: none;
  }
}
.l-block--service-default .l-block--service-info,
.l-block--service-default .l-block--service-instagram {
  margin: 60px auto;
  padding: 0;
}
.l-block--service-default .l-block--service-button {
  margin: 0;
  padding: 0;
}

.l-block--service-info {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
  position: relative;
}
.l-block--service-info__wrap {
  border: 1px solid #b05e61;
}
.l-block--service-info__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  cursor: pointer;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-block--service-info__title {
    padding: 22px 17px;
  }
}
.l-block--service-info__icon {
  display: block;
}
.l-block--service-info__body {
  padding: 0 30px;
  height: 0px;
  opacity: 0;
}
@media (max-width: 768px) {
  .l-block--service-info__body {
    padding: 0 17px;
  }
}
.l-block--service-info__list {
  display: grid;
  gap: 20px 0;
  margin: 0;
  padding: 30px 0;
  border-top: 1px solid #b05e61;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .l-block--service-info__list {
    padding: 23px 0 30px 0;
  }
}
.l-block--service-info__list-item {
  display: flex;
  gap: 50px;
}
@media (max-width: 768px) {
  .l-block--service-info__list-item {
    flex-direction: column;
    gap: 0px;
  }
}
.l-block--service-info__list-item__title, .l-block--service-info__list-item__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(15 / 1366 * 100vw), 1.5rem);
  font-weight: 400;
  letter-spacing: 0.05rem;
  line-height: clamp(24.5px, calc(26.25 / 1366 * 100vw), 26.25px);
  color: #f2eee7;
}
.l-block--service-info__list-item__title {
  max-width: 80px;
  width: 100%;
  display: inline-block;
}
.l-block--service-info__list-item a {
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-block--service-info__list-item a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.l-block--service-about {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
  position: relative;
}
.l-block--service-about__title {
  margin-bottom: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-about__wrap {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 768px) {
  .l-block--service-about__wrap {
    flex-direction: column;
  }
}
.l-block--service-about__body {
  max-width: 536px;
  width: 100%;
  padding-top: 20px;
}
@media (max-width: 768px) {
  .l-block--service-about__body {
    padding: 0;
  }
}
.l-block--service-about__img {
  width: 100%;
  height: auto;
  aspect-ratio: 1.6/1;
  overflow: hidden;
}
.l-block--service-about__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.l-block--service-about__heading {
  margin-bottom: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(27px, calc(36 / 1366 * 100vw), 36px);
  color: #f2eee7;
}
.l-block--service-about__text {
  margin-bottom: 40px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-block--service-about__text {
    margin-bottom: 0;
  }
}

.l-block--service-member {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
}
.l-block--service-member__inner {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #bfb9ae;
}
@media (max-width: 768px) {
  .l-block--service-member__inner {
    display: block;
    flex-direction: column;
  }
}
.l-block--service-member__box {
  width: 100%;
  height: 100%;
  padding: 30px 90px;
}
.l-block--service-member__box:nth-child(1) {
  border-right: 1px solid #bfb9ae;
}
@media (max-width: 768px) {
  .l-block--service-member__box:nth-child(1) {
    border-bottom: 1px solid #bfb9ae;
  }
}
@media (max-width: 768px) {
  .l-block--service-member__box {
    padding: 30px 16px;
  }
}
.l-block--service-member__text {
  max-width: 344px;
  width: 100%;
  display: flex;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: clamp(28px, calc(31.5 / 1366 * 100vw), 31.5px);
  color: #f2eee7;
}

.l-block--service-mail {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
}
.l-block--service-mail .c-button__text {
  letter-spacing: 0;
  font-size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem);
}
.l-block--service-mail__inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(30px, calc(41 / 1366 * 100vw), 41px);
  padding-top: 45px;
  background: #4b0d10;
}
@media (max-width: 768px) {
  .l-block--service-mail__inner {
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 18px 36px 17px;
  }
}
.l-block--service-mail__body {
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .l-block--service-mail__body {
    padding: 0 8px;
    padding-bottom: 0;
  }
}
.l-block--service-mail__title {
  position: relative;
  z-index: 2;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 28px;
  color: #f2eee7;
}
.l-block--service-mail__text {
  position: relative;
  z-index: 2;
  margin: 30px 0 23px 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(15 / 1366 * 100vw), 1.5rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(19.5px, calc(26.25 / 1366 * 100vw), 26.25px);
  color: #f2eee7;
}
.l-block--service-mail__link {
  position: relative;
  z-index: 2;
  text-decoration: none;
  transition: 0.5s;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(19.5px, calc(26.25 / 1366 * 100vw), 26.25px);
  color: #f2eee7;
}
@media (hover: hover) {
  .l-block--service-mail__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-block--service-mail__img {
  position: relative;
  z-index: 2;
  position: relative;
  max-width: 536px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1.8/1;
}
.l-block--service-mail__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.l-block--service-mail__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.38;
}
.l-block--service-mail__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  mix-blend-mode: hard-light;
}
.l-block--service-mail__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.l-block--service-trading {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.l-block--service-trading__title {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-trading__title span {
  display: block;
  transform: translateX(20px);
}
.l-block--service-trading__list {
  max-width: 904px;
  width: 100%;
  display: grid;
  gap: clamp(9px, calc(16 / 1366 * 100vw), 16px);
  grid-template-columns: repeat(5, 1fr);
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .l-block--service-trading__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.l-block--service-trading__list-item {
  aspect-ratio: 1/1;
  background: #fff;
}
.l-block--service-trading__list-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.l-block--service-case {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 768px) {
  .l-block--service-case {
    flex-direction: column;
    gap: 0;
  }
}
.l-block--service-case__title {
  white-space: nowrap;
  margin-bottom: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-case__body {
  display: grid;
  gap: 20px;
  max-width: 904px;
  width: 100%;
}
@media (max-width: 768px) {
  .l-block--service-case__body {
    gap: 50px;
  }
}
.l-block--service-case__content {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: clamp(20px, calc(40 / 1366 * 100vw), 40px);
  width: 100%;
  height: fit-content;
}
@media (max-width: 768px) {
  .l-block--service-case__content {
    grid-template-columns: 1fr;
  }
}
.l-block--service-case__inner {
  display: grid;
  gap: clamp(10px, calc(30 / 1366 * 100vw), 30px);
}
.l-block--service-case__img {
  max-width: 352px;
  width: 100%;
  aspect-ratio: 1.5/1;
  overflow: hidden;
}
.l-block--service-case__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .l-block--service-case__img {
    max-width: none;
  }
}
.l-block--service-case__heading {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-block--service-case__heading {
    margin-top: 20px;
  }
}
.l-block--service-case__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 24.5px;
  color: #f2eee7;
}

.l-block--service-price {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .l-block--service-price {
    flex-direction: column;
    align-items: center;
  }
}
.l-block--service-price__label {
  margin-bottom: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-price__body {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 768px) {
  .l-block--service-price__body {
    flex-direction: column;
    align-items: center;
  }
}
.l-block--service-price__content {
  display: flex;
  justify-content: center;
  padding: clamp(75px, calc(126 / 1366 * 100vw), 126px) clamp(17px, calc(30 / 1366 * 100vw), 30px) clamp(35px, calc(80 / 1366 * 100vw), 80px) clamp(17px, calc(30 / 1366 * 100vw), 30px);
  text-align: center;
  border: 1px solid #bfb9ae;
  border-radius: 600px 600px 0 0;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .l-block--service-price__content {
    padding-left: 17px;
    padding-right: 17px;
  }
}
.l-block--service-price__inner {
  width: clamp(235px, calc(331 / 1366 * 100vw), 331px);
}
.l-block--service-price__heading {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-price__main {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-price__main--unit {
  margin-right: 8px;
}
.l-block--service-price__main--month {
  margin-left: 5px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-price__title {
  margin: 15px 0 25px 0;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(4rem, calc(80 / 1366 * 100vw), 8rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #b05e61;
}
@media (max-width: 768px) {
  .l-block--service-price__title {
    margin: 5px 0 25px 0;
  }
}
.l-block--service-price__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: clamp(24.5px, calc(31.5 / 1366 * 100vw), 31.5px);
  color: #f2eee7;
}

.l-block--service-instagram {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: grid;
  gap: 40px 31px;
  margin: 100px auto 100px auto;
}
@media (max-width: 768px) {
  .l-block--service-instagram {
    gap: 15px;
  }
}
.l-block--service-instagram__inner {
  display: grid;
  max-width: 996px;
  gap: 40px 31px;
  margin: auto 0 auto auto;
}
@media (max-width: 768px) {
  .l-block--service-instagram__inner {
    gap: 15px;
  }
}
.l-block--service-instagram__title {
  grid-column: 1/2;
  grid-row: 1/2;
  font-family: "Cormorant Garamond", YuMincho, serif !important;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-instagram__title span {
  display: block;
  transform: translateX(20px);
}
.l-block--service-instagram-button {
  grid-column: 1/2;
  grid-row: 2/3;
}
@media (max-width: 768px) {
  .l-block--service-instagram-button {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
.l-block--service-instagram__content {
  max-width: 812px;
  width: 100%;
  grid-column: 2/3;
  grid-row: 1/4;
}
@media (max-width: 768px) {
  .l-block--service-instagram__content {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
.l-block--service-instagram .l-block--service-button {
  margin: 0;
  padding: 0;
}
.l-block--service-instagram #sbi_images {
  padding: 0 !important;
  margin: 0 !important;
  gap: clamp(10px, calc(25 / 1366 * 100vw), 25px) !important;
}

.l-block--service-flow {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
}
.l-block--service-flow__title {
  position: relative;
  text-align: center;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-flow__title span {
  position: relative;
  z-index: 1;
  background: #931f26;
  padding: 0 20px;
}
.l-block--service-flow__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  background: #b05e61;
}
.l-block--service-flow__list {
  display: flex;
  justify-content: center;
  gap: 46px;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  padding-bottom: 60px;
  border-bottom: 1px solid #b05e61;
}
@media (max-width: 768px) {
  .l-block--service-flow__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
  }
}
.l-block--service-flow__list-item {
  max-width: 180px;
  width: 100%;
}
@media (max-width: 768px) {
  .l-block--service-flow__list-item {
    max-width: 100%;
    text-align: center;
  }
}
.l-block--service-flow__list-item__label {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-flow__list-item__title {
  margin: 8px 0 20px 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-flow__list-item__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 19.5px;
  color: #f2eee7;
}

.l-block--service-plan {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}
.l-block--service-plan__title {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-plan__body {
  max-width: 812px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 768px) {
  .l-block--service-plan__body {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 11px;
  }
}
.l-block--service-plan__img {
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}
.l-block--service-plan__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.l-block--service-plan__heading {
  margin-top: clamp(10px, calc(20 / 1366 * 100vw), 20px);
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}
.l-block--service-plan__inner {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}
.l-block--service-plan__item, .l-block--service-plan__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}

.l-block--service-button {
  max-width: 720px;
  width: 100%;
  margin: 100px auto;
  padding: 0 20px;
}

/*--------------------
company plan
--------------------*/
.l-block--corporate-plans-default {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin: clamp(80px, calc(100 / 1366 * 100vw), 100px) auto;
  width: 100%;
}
.l-block--corporate-plans-default__wrap {
  position: relative;
  padding-left: clamp(17px, calc(92 / 1366 * 100vw), 92px);
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__wrap {
    padding-left: 17px;
  }
}
.l-block--corporate-plans-default__wrap::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 30px;
  margin: auto;
  width: 1px;
  height: calc(100% - 50px);
  background: #b05e61;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__wrap::after {
    left: 0;
    top: -10px;
    height: calc(100% + 10px);
  }
}
.l-block--corporate-plans-default__title {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--corporate-plans-default__flex {
  display: flex;
  justify-content: space-between;
  gap: clamp(40px, calc(50 / 1366 * 100vw), 50px);
  flex-direction: row-reverse;
  margin-top: -38px;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__flex {
    display: block;
    margin-top: 30px;
  }
}
.l-block--corporate-plans-default__img {
  width: 318px;
  height: 318px;
}
.l-block--corporate-plans-default__img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__img img {
    max-width: none;
    width: 100%;
  }
}
.l-block--corporate-plans-default__head {
  flex: 1;
}
.l-block--corporate-plans-default__heading {
  margin-top: clamp(40px, calc(110 / 1366 * 100vw), 110px);
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-block--corporate-plans-default__text {
  margin: 30px 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
}
.l-block--corporate-plans-default__plan {
  display: flex;
  border: 1px solid #f2eee7;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__plan {
    display: block;
  }
}
.l-block--corporate-plans-default__plan__items {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.03rem;
  color: #f2eee7;
  margin: 0;
  padding: 25px 40px 25px 30px;
  flex: 1;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__plan__items {
    padding: 20px 16px;
  }
}
.l-block--corporate-plans-default__plan__left {
  border-right: 1px solid #f2eee7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.6;
  padding: 8px;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__plan__left {
    border-right: 0px;
    border-bottom: 1px solid #f2eee7;
    padding-top: 25px;
    padding-bottom: 20px;
    align-items: center;
    width: 100%;
  }
}
.l-block--corporate-plans-default__plan__price {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: calc((1em - 1lh) / 3.5);
  color: #f2eee7;
  white-space: nowrap;
}
.l-block--corporate-plans-default__plan__year {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
  margin-left: 3px;
}
.l-block--corporate-plans-default__plan__update {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.5rem, calc(15 / 1366 * 100vw), 1.5rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(22.75px, 100%, 28px);
  color: #f2eee7;
  text-align: center;
}
.l-block--corporate-plans-default__plan:nth-child(n+1) {
  margin-top: 30px;
}
@media (max-width: 768px) {
  .l-block--corporate-plans-default__plan:nth-child(n+1) {
    margin-top: 40px;
  }
}
.l-block--corporate-plans-default__plan:nth-child(n+3) {
  margin-top: 20px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
.c-loading {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100vh;
  background: #931f26;
  transition: 0.6s;
  z-index: 9999;
}
.c-loading__circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  border: 1px solid #f2eee7;
  border-radius: 100px;
}
.c-loading__circle::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 92%;
  height: 104%;
  background: #931f26;
  filter: blur(1px);
  border-radius: 80px;
  animation: loading infinite 0.6s linear;
}

.c-loading--close {
  opacity: 0;
  pointer-events: none;
  transition: 0.6s;
}

/*! modern-css-reset | https://github.com/Andy-set-studio/modern-css-reset */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list],
li {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: #931f26;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #000;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

img {
  width: 100%;
  vertical-align: bottom;
}

.l-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  z-index: 999;
}
@media (max-width: 768px) {
  .l-header {
    padding: 0 20px;
  }
}
.l-header__logo {
  transition: 0.5s;
}
@media (hover: hover) {
  .l-header__logo:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-header__list-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.l-header__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 0;
}
@media (max-width: 768px) {
  .l-header__list {
    display: none;
  }
}
.l-header__list-item__link {
  text-decoration: none;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: calc(28 / 16);
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-header__list-item__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-header--sp {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #931f26;
  opacity: 0;
  pointer-events: none;
  transition: 0.6s;
  z-index: -1;
}
.l-header--sp__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: url(../../img/front/fv.jpg) no-repeat center/cover;
  opacity: 0.6;
  mix-blend-mode: hard-light;
  z-index: -1;
}
.l-header--sp__logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-header--sp__logo:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
@media (max-width: 768px) {
  .l-header--sp__logo {
    display: none;
  }
}
.l-header--sp__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(940px + 20px * 2);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 5%;
  padding-left: 20px;
  padding-right: 20px;
}
.l-header--sp__list-wrap {
  max-width: 301px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #931f26;
  border-radius: 600px 600px 0 0;
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 768px) {
  .l-header--sp__list-wrap {
    padding-top: 140px;
    padding-bottom: 100px;
    margin: auto;
  }
}
.l-header--sp__list-top, .l-header--sp__list-bottom {
  padding: 0;
  margin: 0;
}
.l-header--sp__list-top {
  display: grid;
  gap: 30px;
}
.l-header--sp__list-top-item {
  text-align: center;
}
.l-header--sp__list-top-item__link {
  text-decoration: none;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: calc(28 / 16);
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-header--sp__list-top-item__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-header--sp__list-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 30px;
  margin-top: 120px;
}
.l-header--sp__list-bottom-item__link {
  text-decoration: none;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-header--sp__list-bottom-item__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-header--hamburger {
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-header--hamburger:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
@media (max-width: 768px) {
  .l-header--hamburger {
    justify-content: flex-end;
  }
}
.l-header--hamburger span {
  position: relative;
  display: block;
  width: 20px;
  height: 8px;
}
.l-header--hamburger span::before, .l-header--hamburger span::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: 20px;
  height: 1px;
  background: #f2eee7;
  transition: 0.3s;
}
.l-header--hamburger span::before {
  top: 0;
}
.l-header--hamburger span::after {
  bottom: 0;
}

.js-active-hamburger span::before, .js-active-hamburger span::after {
  transition: 0.3s;
}
.js-active-hamburger span::before {
  transform: translate(0, 5px) rotate(20deg);
}
.js-active-hamburger span::after {
  transform: translate(0, -2px) rotate(-20deg);
}

.js-active-sp-nav {
  opacity: 1;
  pointer-events: all;
  z-index: 1;
  transition: 0.6s;
}

:root {
  --leading-trim: calc((1em - 1lh) / 2);
}

.l-footer {
  margin-top: auto;
}
.l-footer a {
  color: #f2eee7;
  text-decoration: none;
}
.l-footer-parallax {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.l-footer-parallax__img {
  position: relative;
  max-width: clamp(244px, calc(352 / 1366 * 100vw), 352px);
  height: clamp(319px, calc(460 / 1366 * 100vw), 460px);
  margin: 0 auto;
  overflow: hidden;
  isolation: isolate;
  border-radius: 685px 681px 0 0;
}
.l-footer-parallax__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.48;
}
.l-footer-parallax__logo {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: clamp(335px, calc(718 / 1366 * 100vw), 718px);
  width: 100%;
  margin: 0 auto;
  padding-bottom: 10px;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-footer-parallax__logo:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-footer-parallax__logo svg {
  width: 100%;
}
.l-footer-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.l-footer-nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 30px;
  margin: 35px 0 30px 0;
  padding: 0;
}
.l-footer-nav__list ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .l-footer-nav__list {
    max-width: 263px;
    width: 100%;
    margin: 30px auto 41px auto;
  }
}
.l-footer-nav__list-item__link {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-footer-nav__list-item__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-footer-info {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px 0;
  padding-top: 23px;
  padding-bottom: 24px;
}
.l-footer-info ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .l-footer-info {
    flex-direction: column;
    justify-content: center;
  }
}
.l-footer-info__inner {
  border-top: 1px solid #b05e61;
}
.l-footer-info__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 30px;
}
@media (max-width: 768px) {
  .l-footer-info__list {
    max-width: 280px;
    margin: 0 auto;
    gap: 10px 30px;
  }
}
.l-footer-info__sns {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 15px 30px;
}
.l-footer-info__sns-item {
  transition: 0.5s;
}
@media (hover: hover) {
  .l-footer-info__sns-item:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-footer-info__list-item__link {
  text-decoration: none;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem);
  font-weight: 500;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-footer-info__list-item__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-footer-info-wrap {
  border-top: 1px solid #b05e61;
}
@media (max-width: 768px) {
  .l-footer-info-wrap {
    padding: 0 20px;
    border: none;
  }
}
.l-footer-bottom {
  container-type: inline-size;
  padding: 20px 0 30px 0;
  background: #4d1418;
}
.l-footer-bottom-wrap {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 30px;
}
@container (max-width: 925px) {
  .l-footer-bottom-wrap {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .l-footer-bottom-wrap {
    flex-direction: column;
  }
}
.l-footer-logo__list {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: clamp(10px, calc(16 / 1366 * 100vw), 16px);
}
.l-footer-logo__list ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .l-footer-logo__list {
    grid-template-columns: repeat(2, auto);
  }
}
.l-footer-logo__list-item {
  max-width: 168px;
  width: 100%;
  transition: 0.5s;
}
@media (hover: hover) {
  .l-footer-logo__list-item:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-footer-copy {
  text-align: center;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}

.l-main {
  padding-bottom: 200px;
}
.l-main__head {
  position: relative;
  padding: 0 44px;
}
.l-main__head::before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: clamp(180px, calc(286 / 1366 * 100vw), 286px);
  background-image: url("../../img/common/page-head.png");
  background-size: cover;
  opacity: 0.6;
  mix-blend-mode: hard-light;
}
@media screen and (min-width: 950px) and (max-width: 1110px) {
  .l-main__head::before {
    height: clamp(220px, calc(234 / 1110 * 100vw), 234px);
  }
}
@media screen and (min-width: 770px) and (max-width: 950px) {
  .l-main__head::before {
    height: clamp(205px, calc(220 / 950 * 100vw), 220px);
  }
}
@media (max-width: 768px) {
  .l-main__head::before {
    height: clamp(180px, calc(200 / 770 * 100vw), 200px);
  }
}
@media (max-width: 768px) {
  .l-main__head {
    padding: 0 20px;
  }
}
.l-main__head-title {
  position: relative;
  padding: clamp(133px, calc(155 / 1366 * 100vw), 155px) 0 clamp(80px, calc(84 / 1366 * 100vw), 84px);
  margin: 0 auto;
  margin-block: calc((1em - 1lh) / 2);
  max-width: 1086px;
  width: 100%;
  text-transform: capitalize;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(4.6rem, calc(120 / 1366 * 100vw), 12rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(200 / 120);
  color: #f2eee7;
}

.scroll {
  display: none;
  position: fixed !important;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: calc(100svh - 60px);
  overflow: scroll;
  z-index: 1000;
  border: 1px solid transparent;
  z-index: 10000;
  scroll-bar-width: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.scroll .scroll-inner {
  width: 100%;
  height: 1000vh;
}
.scroll .scroll-inner::-webkit-scrollbar {
  display: none;
}

body {
  overflow-x: hidden;
  scroll-bar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}

.p-top {
  position: relative;
}
.p-top-cover {
  position: relative;
  --bg-color: none;
  --z-index: 1000;
  --opacity: 1;
  transition: 0.6s;
}
.p-top-cover::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: var(--bg-color);
  z-index: var(--z-index);
  opacity: var(--opacity);
  transition: 0.6s;
}
.p-top-fv {
  position: relative;
  width: 100%;
  height: 100svh;
  overflow: hidden;
}
.p-top-fv__inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 100svh;
}
.p-top-fv__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  opacity: 0.6;
  mix-blend-mode: hard-light;
  z-index: -10;
}
.p-top-fv__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-top-fv-mask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100svh;
}
.p-top-fv__center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: auto;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-top-fv__img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: clamp(302px, calc(568 / 1366 * 100vw), 568px);
  width: 100%;
  aspect-ratio: 1/1.25;
  margin: auto;
  border-radius: 600px 600px 0 0;
  overflow: hidden;
  isolation: isolate;
}
.p-top-fv__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.48;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-top-fv__img {
    max-width: clamp(400px, calc(750 / 1024 * 100vw), 750px);
  }
}
@media (max-width: 768px) {
  .p-top-fv__img {
    aspect-ratio: 1/1.67;
    max-width: clamp(302px, calc(568 / 786 * 100vw), 568px);
  }
}
.p-top-fv__img img {
  transition: transform 1.2s cubic-bezier(0.33, 1, 0.68, 1);
  max-width: none !important;
  width: 150%;
}
.p-top-fv__title {
  position: absolute;
  right: 0;
  bottom: 71px;
  left: 0;
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  animation: zoom 5s infinite ease;
  transform-origin: bottom bottom;
}
.p-top-fv__text {
  position: absolute;
  right: 0;
  bottom: 17px;
  left: 0;
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  animation: zoom 8s infinite ease;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-top-fv__text {
    max-width: 850px;
  }
}
.p-top-about {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(100px, calc(160 / 1366 * 100vw), 160px);
  max-width: calc(1253px + 20px * 2);
  height: clamp(369px, calc(732 / 1366 * 100vw), 732px);
  margin-top: 160px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
}
@media (max-width: 768px) {
  .p-top-about {
    flex-direction: column;
    gap: 46px;
    padding: 0 20px;
    height: auto;
    margin-top: 60px;
  }
}
.p-top-about__img {
  position: relative;
  max-width: clamp(324px, calc(642 / 1366 * 100vw), 642px);
  width: 100%;
  height: clamp(369px, calc(732 / 1366 * 100vw), 732px);
  border-radius: 100% 100% 0 0;
  margin-left: clamp(-360px, calc(220 / -1366 * 100vw), -220px);
  overflow: hidden;
  isolation: isolate;
}
.p-top-about__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.48;
}
@media (max-width: 768px) {
  .p-top-about__img {
    margin-left: -120px;
  }
}
.p-top-about__img source,
.p-top-about__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-top-about__content {
  max-width: 450px;
  height: fit-content;
}
.p-top-about__title {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-top-about__sub-title {
  position: relative;
  max-width: clamp(255px, calc(315 / 1366 * 100vw), 315px);
  width: 80%;
  margin: clamp(40px, calc(60 / 1366 * 100vw), 60px) 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}
.p-top-about__sub-title::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: clamp(30px, calc(48 / 1366 * 100vw), 48px);
  height: 1px;
  background: #f2eee7;
}
.p-top-about__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(15 / 1366 * 100vw), 1.5rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: calc(clamp(1.4rem, calc(15 / 1366 * 100vw), 1.5rem) * 1.75);
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-top-about__text {
    letter-spacing: 0.04em;
  }
}
.p-top-about__text--en {
  margin: 40px 0 clamp(50px, calc(60 / 1366 * 100vw), 60px) 0;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: calc(1.4rem * 1.25);
  color: #bfb9ae;
}
@media (max-width: 768px) {
  .p-top-about__button {
    width: fit-content;
    margin: auto 0 auto auto;
  }
}
.p-top-service {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  position: relative;
  margin-top: 160px;
  display: grid;
  grid-template-columns: 47px 1fr;
  gap: clamp(50px, calc(112 / 1366 * 100vw), 112px);
}
@media (max-width: 768px) {
  .p-top-service {
    display: block;
  }
}
.p-top-service__left {
  position: relative;
}
.p-top-service__left::before, .p-top-service__left::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #b05e61;
}
.p-top-service__left::before {
  top: 0;
  left: 0;
}
@media (max-width: 768px) {
  .p-top-service__left::before {
    display: none;
  }
}
.p-top-service__left::after {
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 80%;
  height: 100%;
}
@media (max-width: 768px) {
  .p-top-service__left::after {
    max-height: 1px;
    width: 85%;
    top: 150%;
    right: 0;
    bottom: 0;
    left: auto;
  }
}
.p-top-service__title {
  transform: rotate(-90deg) translateX(-60px);
  transform-origin: center center;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-top-service__title {
    width: fit-content;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  }
}
.p-top-service__content {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 20px 40px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .p-top-service__content {
    gap: 30px;
    grid-template-columns: auto;
    padding-top: 120px;
  }
}
.p-top-service__content .c-service-card:nth-child(odd) {
  margin-top: -60px;
}
@media (max-width: 768px) {
  .p-top-service__content .c-service-card:nth-child(odd) {
    margin-top: 0;
  }
}
.p-top-line {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin-top: 120px;
  margin-bottom: 100px;
  transition: 0.5s;
}
@media (hover: hover) {
  .p-top-line:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
@media (max-width: 768px) {
  .p-top-line {
    margin: 80px 0;
  }
}
.p-top-news {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: flex;
  gap: clamp(100px, calc(200 / 1366 * 100vw), 200px);
}
@media (max-width: 768px) {
  .p-top-news {
    flex-direction: column;
    gap: 40px;
  }
}
.p-top-news .p-news-archive__list {
  padding: 0;
}
.p-top-news__title {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-top-news__content {
  width: 100%;
}
.p-top-news__button {
  width: fit-content;
  margin: auto 0 auto auto;
}
.p-top-pr {
  padding: 96px 0 100px 0;
  background: url(../../img/front/pr-bg.jpg) no-repeat center/cover;
}
@media (max-width: 768px) {
  .p-top-pr {
    padding: 50px 20px 80px 20px;
  }
}
.p-top-pr__inner {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
}
.p-top-pr__content {
  width: fit-content;
  margin: auto 0 auto auto;
}
@media (max-width: 768px) {
  .p-top-pr__content {
    width: 100%;
  }
}
.p-top-pr__title {
  opacity: 0.45;
  margin-bottom: 65px;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(4rem, calc(80 / 1366 * 100vw), 8rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-top-pr__title {
    margin-bottom: 140px;
  }
}
.p-top-pr__label {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-top-pr__text {
  margin: 35px 0 40px 0;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.3rem, calc(15 / 1366 * 100vw), 1.5rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-top-pr__button {
  width: fit-content;
  margin: auto 0 auto auto;
}

.p-news-archive__content {
  max-width: calc(1088px + (44px * 2));
  padding: 0 44px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .p-news-archive__content {
    padding: 0;
  }
}
.p-news-archive__row {
  display: flex;
  gap: clamp(30px, calc(96 / 1366 * 100vw), 96px);
}
@media (max-width: 768px) {
  .p-news-archive__row {
    flex-wrap: wrap;
  }
}
.p-news-archive__nav {
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .p-news-archive__nav {
    width: 100%;
  }
}
.p-news-archive__nav-list {
  list-style: none;
  padding: 0;
  position: sticky;
  top: 150px;
  left: 0;
  margin: 0;
}
@media (max-width: 768px) {
  .p-news-archive__nav-list {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    margin-bottom: 50px;
    padding: 0 30px;
  }
}
@media (max-width: 768px) {
  .p-news-archive__nav-list::-webkit-scrollbar {
    display: none;
  }
}
.p-news-archive__nav-item {
  margin-bottom: 16px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(25 / 13);
  color: #bfb9ae;
}
.p-news-archive__nav-item-link {
  text-decoration: none;
  color: #bfb9ae;
  position: relative;
  width: fit-content;
  padding-bottom: 3px;
}
.p-news-archive__nav-item-link::after {
  content: "";
  display: block;
  position: absolute;
  width: 0%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: #f2eee7;
  transition: width ease 0.25s;
}
.p-news-archive__nav-item-link:hover::after {
  width: 100%;
}
.p-news-archive__nav-item.is-active .p-news-archive__nav-item-link {
  color: #f2eee7;
}
.p-news-archive__nav-item.is-active .p-news-archive__nav-item-link::after {
  width: 100%;
}
@media (max-width: 768px) {
  .p-news-archive__nav-item {
    margin-bottom: 0;
  }
  .p-news-archive__nav-item:not(:first-child) {
    margin-left: 10px;
  }
}
.p-news-archive__list {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 0 60px;
}
@media (max-width: 768px) {
  .p-news-archive__list {
    padding: 0 20px;
    gap: 20px;
  }
}
.p-news-archive__item-link {
  display: flex;
  text-decoration: none;
  gap: clamp(10px, calc(18 / 1366 * 100vw), 18px);
  position: relative;
}
.p-news-archive__item-link::after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 15px;
  bottom: 30px;
  right: 20px;
  transition: all ease 0.25s;
  background-image: url("../../img/common/button-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform ease 0.25s;
}
@media (max-width: 768px) {
  .p-news-archive__item-link::after {
    bottom: 20px;
    right: 10px;
  }
}
@media (hover: hover) {
  .p-news-archive__item-link:hover::after {
    transform: translateX(10px);
  }
  .p-news-archive__item-link:hover .p-news-archive__item-thumbnail img {
    transform: scale(1.2);
    transition: 0.45s;
  }
  .p-news-archive__item-link:hover .p-news-archive__item-inner::after {
    transform: scaleX(100%);
  }
}
.p-news-archive__item-thumbnail {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  overflow: hidden;
}
@media (max-width: 768px) {
  .p-news-archive__item-thumbnail {
    width: 110px;
    height: 110px;
  }
}
.p-news-archive__item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.45s;
}
.p-news-archive__item-inner {
  width: 100%;
  padding: 30px 32px 45px;
  border-bottom: 1px solid #b05e61;
  position: relative;
}
@media (max-width: 768px) {
  .p-news-archive__item-inner {
    padding: 0 32px 32px 5px;
  }
}
.p-news-archive__item-inner::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  background: #f2eee7;
  transition: transform ease 0.25s;
  transform-origin: 0 0;
}
.p-news-archive__item-date {
  display: inline-block;
  margin-right: 15px;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(22 / 18);
  color: #bfb9ae;
}
.p-news-archive__item-category {
  display: inline-block;
}
@media (max-width: 768px) {
  .p-news-archive__item-category {
    display: block;
  }
}
.p-news-archive__item-category-item {
  display: inline-block;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 13);
  color: #bfb9ae;
}
@media (max-width: 768px) {
  .p-news-archive__item-category-item {
    display: block;
    font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
    font-size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1;
    color: #bfb9ae;
  }
}
.p-news-archive__item-title {
  max-width: 495px;
  width: 100%;
  margin-top: 16px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.p-news-single__head::before {
  height: 200px;
}
.p-news-single__head-title {
  padding: 138px 0 100px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 6rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(100 / 60);
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-news-single__head-title {
    padding-bottom: 55px;
  }
}
.p-news-single__wrapper {
  max-width: calc(1088px + (44px * 2));
  margin: 0 auto;
  padding: 0 44px;
  display: flex;
  gap: 60px;
}
@media (max-width: 768px) {
  .p-news-single__wrapper {
    display: block;
    padding: 0 20px;
  }
}
.p-news-single__thumbnail {
  width: clamp(278px, calc(308 / 1366 * 100vw), 308px);
  height: clamp(278px, calc(308 / 1366 * 100vw), 308px);
  aspect-ratio: 1/1;
  position: sticky;
  top: 150px;
  left: 0;
  flex-shrink: 0;
}
.p-news-single__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .p-news-single__thumbnail {
    position: static;
  }
}
.p-news-single__content {
  margin-bottom: 120px;
}
@media (max-width: 768px) {
  .p-news-single__content {
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
.p-news-single__date {
  display: inline-block;
  margin-right: 15px;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.8rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(22 / 18);
  color: #bfb9ae;
}
.p-news-single__category {
  display: inline-block;
}
.p-news-single__category-item {
  display: inline-block;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(13 / 1366 * 100vw), 1.3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 13);
  color: #bfb9ae;
}
.p-news-single__title {
  margin-top: 26px;
  padding-bottom: 46px;
  margin-bottom: 60px;
  border-bottom: 1px solid #b05e61;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: calc(10 / 1000 * 1em);
  line-height: calc(28 / 20);
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-news-single__title {
    margin-top: 20px;
    padding-bottom: 50px;
    margin-bottom: 80px;
  }
}
.p-news-single__content-body {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
.p-news-single__content-body p {
  margin-top: 50px;
}
.p-news-single__content-body p:empty {
  height: 1.5em;
  margin: 0;
}
.p-news-single__content-body a {
  color: #f2eee7;
  transition: 0.5s;
}
@media (hover: hover) {
  .p-news-single__content-body a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.p-news-single__back-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-decoration: none;
}
@media (hover: hover) {
  .p-news-single__back-btn:hover .p-news-single__back-icon {
    transform: translateX(-10px);
  }
}
.p-news-single__back-icon {
  transition: transform ease 0.25s;
}
.p-news-single__back-text {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: calc(200 / 1000 * 1em);
  line-height: calc(28 / 20);
  color: #f2eee7;
}

.p-service-single {
  padding-bottom: 100px;
}
.p-service-single-nav {
  position: relative;
  width: 100%;
  border-top: 1px solid #b05e61;
  border-bottom: 1px solid #b05e61;
  padding: clamp(12px, calc(30 / 1366 * 100vw), 30px) 0;
  margin-top: -84px;
  z-index: 10;
  background: #931f26;
}
@media (max-width: 768px) {
  .p-service-single-nav {
    margin-top: 0;
  }
}
.p-service-single-nav__list {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: clamp(20px, calc(60 / 1366 * 100vw), 60px);
  overflow-x: auto;
  margin: 0 auto !important;
}
.p-service-single-nav__list::-webkit-scrollbar {
  display: none;
}
.p-service-single-nav__list-item__link {
  text-decoration: none;
  transition: 0.5s;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}
@media (hover: hover) {
  .p-service-single-nav__list-item__link:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.p-service-single-content {
  margin-top: clamp(80px, calc(120 / 1366 * 100vw), 120px);
}
.p-service-single .c-button__link {
  padding: 0;
}

/*--------------------
service single head
--------------------*/
.l-service {
  padding-bottom: 200px;
}
.l-service__head {
  position: relative;
  padding: 0 44px;
}
.l-service__head::before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: clamp(180px, calc(286 / 1366 * 100vw), 286px);
  background-image: url("../../img/common/page-head.png");
  background-size: cover;
  opacity: 0.6;
  mix-blend-mode: hard-light;
}
@media screen and (min-width: 950px) and (max-width: 1110px) {
  .l-service__head::before {
    height: clamp(200px, calc(234 / 1110 * 100vw), 234px);
  }
}
@media screen and (min-width: 769px) and (max-width: 950px) {
  .l-service__head::before {
    height: clamp(0px, calc(200 / 950 * 100vw), 200px);
  }
}
@media (max-width: 768px) {
  .l-service__head::before {
    height: clamp(243px, calc(353 / 770 * 100vw), 353px);
  }
}
@media (max-width: 768px) {
  .l-service__head {
    padding: 0 20px;
  }
}
.l-service__head-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: clamp(20px, calc(30 / 1366 * 100vw), 30px);
  padding: clamp(80px, calc(190 / 1366 * 100vw), 190px) 0 clamp(80px, calc(84 / 1366 * 100vw), 84px);
  margin: 0 auto;
  margin-block: calc((1em - 1lh) / 2);
  max-width: 1086px;
  width: 100%;
}
@media (max-width: 768px) {
  .l-service__head-wrap {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.l-service__head-title-wrap {
  max-width: 536px;
  width: 100%;
}
.l-service__head--img {
  position: relative;
  max-width: clamp(244px, calc(444 / 1366 * 100vw), 444px);
  width: 100%;
  height: clamp(301px, calc(547 / 1366 * 100vw), 547px);
  border-radius: 600px 600px 0 0;
  margin-top: -112px;
  overflow: hidden;
  bottom: 0;
}
.l-service__head--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .l-service__head--img {
    margin-top: 0;
  }
}
.l-service__head--img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
}
.l-service__head--label {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.8rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
.l-service__head--number {
  display: inline-block;
  transform: translateY(-13px) translateX(8px);
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-service__head--number {
    transform: translateY(-7px) translateX(5px);
  }
}
.l-service__head--en {
  text-transform: capitalize;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(4.6rem, calc(80 / 1366 * 100vw), 8rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-service__head--en {
    margin: 10px 0;
  }
}
.l-service__head--jp {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: normal;
  color: #f2eee7;
}
.l-service__head--text {
  min-height: 70px;
  margin: clamp(80px, calc(100 / 1366 * 100vw), 100px) 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(26px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
}
@media (max-width: 768px) {
  .l-service__head--text {
    margin-bottom: 0;
    min-height: auto;
  }
}

.p-contact__content {
  max-width: calc(720px + (44px * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
}
.p-contact__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: calc(28 / 14);
  color: #f2eee7;
  margin-bottom: 56px;
}
@media (max-width: 768px) {
  .p-contact__text {
    margin-bottom: 80px;
  }
}
.p-contact__form-item {
  margin-bottom: 30px;
}
.p-contact__form-item select,
.p-contact__form-item input,
.p-contact__form-item textarea {
  background: #ad545a;
  border: none;
  border-radius: 5px;
  width: 100%;
  min-height: 47px;
  padding: 10px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
}
.p-contact__form-item select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.p-contact__form-item-content--select .wpcf7-form-control-wrap {
  position: relative;
  display: block;
}
.p-contact__form-item-content--select .wpcf7-form-control-wrap::before {
  content: "";
  background-image: url("../../img/common/arrow-1.png");
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 10px;
  right: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
}
.p-contact__form-item-title {
  margin-bottom: 15px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(25 / 14);
  color: #f2eee7;
}
.p-contact__form-item-title span {
  padding-left: 10px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(12 / 1366 * 100vw), 1.2rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(21 / 12);
  color: #f2eee7;
}
.p-contact__form-agree-policy {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(25 / 14);
  color: #f2eee7;
}
.p-contact__form-agree-policy .wpcf7-list-item {
  margin-left: 0;
}
@media (max-width: 768px) {
  .p-contact__form-agree-policy {
    text-align: center;
  }
}
.p-contact__form-agree-policy input {
  position: relative;
  width: 17px;
  height: 17px;
  background: #ad545a;
  border-radius: 3px;
  vertical-align: -5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.p-contact__form-agree-policy input:checked::before {
  position: absolute;
  top: 1px;
  left: 5px;
  transform: rotate(50deg);
  width: 7px;
  height: 11px;
  border-right: 1px solid #f2eee7;
  border-bottom: 1px solid #f2eee7;
  content: "";
}
.p-contact__form-agree-policy a {
  margin-left: 8px;
  text-decoration: underline;
  color: #f2eee7;
}
.p-contact__form-submit {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
@media (max-width: 768px) {
  .p-contact__form-submit {
    margin-top: 100px;
  }
}
.p-contact__form-submit input {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 20px 0;
  background: transparent;
  border: none;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 16);
  color: #f2eee7;
  font-size: 16px;
}
@media (max-width: 768px) {
  .p-contact__form-submit input {
    padding: 12px 0;
  }
}
.p-contact__form-submit p {
  display: block;
  width: 100%;
}
.p-contact__form-submit input[type=submit]:disabled {
  color: currentColor;
}
.p-contact__form-submit .c-border-button__text {
  width: 100%;
}
.p-contact__form-submit .c-border-button__link {
  position: relative;
}
.p-contact__form-submit .c-border-button__link::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 50px;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 15px;
  transition: all ease 0.25s;
  background-image: url("../../img/common/button-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 768px) {
  .p-contact__form-submit .c-border-button__link::after {
    right: 40px;
  }
}
@media (hover: hover) {
  .p-contact__form-submit .c-border-button__link:hover::after {
    margin-left: 60px;
  }
}
.p-contact__form-submit .c-border-button__inner {
  padding: 0;
}
.p-contact__form-submit .wpcf7-spinner {
  display: none;
}

.p-contact-complete__content {
  max-width: calc(720px + (44px * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
}
.p-contact-complete__content-sub-title {
  margin-bottom: 50px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 24);
  color: #f2eee7;
}
.p-contact-complete__content-text {
  margin-bottom: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
.p-contact-complete__content-information {
  margin-bottom: 60px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1rem, calc(12 / 1366 * 100vw), 1.2rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(21 / 12);
  color: #f2eee7;
}
.p-contact-complete__button {
  width: 100%;
}

.p-privacy-policy__content {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
.p-privacy-policy__content > * + * {
  margin-top: 2em;
}
.p-privacy-policy__content p:empty {
  height: 1.5em;
}
.p-privacy-policy__content a {
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-privacy-policy__content {
    padding: 0 20px;
  }
}

.p-service__wrap {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
}
.p-service__content {
  max-width: 928px;
  margin: 0 0 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 40px;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .p-service__content {
    gap: 30px;
    grid-template-columns: auto;
    margin: 0 auto;
    padding-top: 0;
  }
}
.p-service__content .c-service-card:nth-child(odd) {
  margin-top: -60px;
}
@media (max-width: 768px) {
  .p-service__content .c-service-card:nth-child(odd) {
    margin-top: 0;
  }
}

.p-about-us-content {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 80px;
}
.p-about-us-content__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: calc(clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem) * 2);
  color: #f2eee7;
}
.p-about-us-content__img {
  max-width: 590px;
  width: 100%;
}
.p-about-us-history {
  width: 100%;
  height: auto;
  margin-top: clamp(200px, calc(220 / 1366 * 100vw), 220px);
}
.p-about-us-history__top {
  position: relative;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 438px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 600px 600px 0 0;
}
@media (max-width: 768px) {
  .p-about-us-history__top {
    width: 190px;
  }
}
.p-about-us-history__top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-about-us-history__top::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
}
.p-about-us-history__body {
  position: relative;
  width: 100%;
  height: 50vh;
  z-index: 100;
  padding-bottom: 140px;
}
@media (max-width: 768px) {
  .p-about-us-history__body {
    height: auto;
  }
}
.p-about-us-history__body-inner {
  position: absolute;
  bottom: 140px;
  left: 140px;
}
@media (max-width: 768px) {
  .p-about-us-history__body-inner {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0 20px;
  }
}
.p-about-us-history__label {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-history__title {
  margin: 40px 0 50px 0;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-history__text {
  max-width: 720px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: clamp(24.5px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
}
.p-about-us-concept {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  position: relative;
  height: 740px;
  margin-top: 120px;
}
.p-about-us-concept ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .p-about-us-concept {
    height: 937px;
  }
}
.p-about-us-concept__title {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-concept__left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 315px;
  width: 100%;
  opacity: 0.85;
  padding-top: 50px;
  border-radius: 600px 600px 0 0;
  background: #62191e;
}
@media (max-width: 768px) {
  .p-about-us-concept__left {
    min-height: 937px;
    margin-left: -90px;
  }
}
.p-about-us-concept__list {
  width: clamp(375px, 100%, 760px);
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  display: grid;
  gap: 35px;
}
@media (max-width: 768px) {
  .p-about-us-concept__list {
    padding: 0 20px !important;
    bottom: 70px;
  }
}
.p-about-us-concept__list-item {
  padding: 0 16px 35px 16px;
  border-bottom: 1px solid #b05e61;
}
.p-about-us-concept__list-item__label {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #c3797d;
}
.p-about-us-concept__list-item__title {
  margin: 14px 0 25px 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-concept__list-item__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(21px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
}
.p-about-us-mission-vision {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  margin-top: 178px;
}
@media (max-width: 768px) {
  .p-about-us-mission-vision {
    margin-top: 78px;
  }
}
.p-about-us-mission-vision__body {
  padding-left: 40px;
}
@media (max-width: 768px) {
  .p-about-us-mission-vision__body {
    padding-left: 90px;
  }
}
.p-about-us-mission-vision__header {
  display: flex;
}
.p-about-us-mission-vision__header:nth-child(2) {
  margin-top: 30px;
  transform: translateX(140px);
}
@media (max-width: 768px) {
  .p-about-us-mission-vision__header:nth-child(2) {
    margin-top: 40px;
    transform: translateX(0);
  }
}
@media (max-width: 768px) {
  .p-about-us-mission-vision__header {
    flex-direction: column;
  }
}
.p-about-us-mission-vision__title {
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(3.6rem, calc(80 / 1366 * 100vw), 8rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-mission-vision__label {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.5rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-mission-vision__ja {
  margin: 10px 0 5px 0;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2.4rem, calc(40 / 1366 * 100vw), 4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-about-us-mission-vision__ja {
    margin: 10px 0;
  }
}
.p-about-us-mission-vision__en {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #c3797d;
}
.p-about-us-mission-vision__img {
  margin-top: 60px;
}
.p-about-us-access {
  border-top: 1px solid #b05e61;
  border-bottom: 1px solid #b05e61;
  margin-top: 100px;
  padding-top: 18px;
  padding-bottom: 18px;
}
@media (max-width: 768px) {
  .p-about-us-access {
    margin-top: 80px;
    padding: 0 20px;
    border-top: none;
    border-bottom: none;
  }
}
.p-about-us-access__inner {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}
@media (max-width: 768px) {
  .p-about-us-access__inner {
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
    border-top: 1px solid #b05e61;
    border-bottom: 1px solid #b05e61;
  }
}
@media (max-width: 768px) {
  .p-about-us-access__info {
    padding: 0 16px;
  }
}
.p-about-us-access__logo {
  max-width: 227px;
  width: 100%;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .p-about-us-access__logo {
    margin: 0 auto 50px auto;
  }
}
.p-about-us-access__address, .p-about-us-access__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 24.5px;
  color: #f2eee7;
}
.p-about-us-access__address {
  display: flex;
  margin-top: 18px;
}
.p-about-us-access__address a {
  color: #f2eee7;
  text-decoration: none;
}
.p-about-us-access__icon {
  width: fit-content;
  margin-right: 10px;
}
.p-about-us-access__text {
  margin-top: clamp(23px, calc(44 / 1366 * 100vw), 30px);
}
.p-about-us-access__map {
  max-width: 536px;
  width: 100%;
}
.p-about-us-access__map iframe {
  filter: grayscale(1);
  min-height: 368px;
}
.p-about-us-map {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: clamp(80px, calc(130 / 1366 * 100vw), 130px);
  margin-bottom: clamp(80px, calc(120 / 1366 * 100vw), 120px);
}
@media (max-width: 768px) {
  .p-about-us-map {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
.p-about-us-map__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}
.p-about-us-map__item {
  width: 100%;
}
.p-about-us-map__item:hover .p-about-us-map__icon {
  opacity: 0.5;
  transition: 0.3s;
}
.p-about-us-map__title {
  white-space: nowrap;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
.p-about-us-map__border {
  width: 100%;
  height: 1px;
  background: #b05e61;
}
.p-about-us-map__icon {
  cursor: pointer;
  transition: 0.3s;
}
.p-about-us-map__img {
  cursor: pointer;
}
.p-about-us-gallery {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  position: relative;
  width: 100%;
  height: 100%;
  height: 530px;
}
@media (max-width: 768px) {
  .p-about-us-gallery {
    padding-left: 0;
    padding-right: 0;
  }
}
.p-about-us-gallery__swiper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  transform: translateY(-45px);
}
@media (max-width: 768px) {
  .p-about-us-gallery__swiper {
    transform: translateY(-25px);
  }
}
.p-about-us-gallery__title {
  font-family: "Cormorant Garamond", YuMincho, serif;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-about-us-gallery__title {
    padding: 0 20px;
  }
}
.p-about-us-gallery__list-item__inner {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
.p-about-us-gallery__list-item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: fit-content;
}
.p-about-us-gallery__list-item__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.48;
}
.p-about-us-gallery__list-item__img img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: 0.3s;
}
.p-about-us-gallery__list-item__text {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: normal;
  color: #f2eee7;
  position: absolute;
  right: 0;
  bottom: -50px;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  opacity: 0;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .p-about-us-gallery__list-item__text {
    bottom: 60px;
  }
}
.p-about-us-gallery .gallery-swiper {
  transition-timing-function: linear !important;
}
.p-about-us-gallery .swiper-button-next,
.p-about-us-gallery .swiper-button-prev {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 15px;
  margin: auto 0;
  z-index: 10;
}
@media (max-width: 768px) {
  .p-about-us-gallery .swiper-button-next,
.p-about-us-gallery .swiper-button-prev {
    display: none;
  }
}
.p-about-us-gallery .swiper-button-next {
  right: 25%;
}
.p-about-us-gallery .swiper-button-prev {
  left: 25%;
}
.p-about-us-gallery .swiper-slide {
  transform: scale(0.8);
  transition: 0.6s;
}
@media (max-width: 768px) {
  .p-about-us-gallery .swiper-slide {
    transform: scale(1);
  }
}
.p-about-us-gallery .swiper-slide-active {
  width: 100%;
  height: auto;
  transition: 0.7s;
}
.p-about-us-gallery .swiper-slide-active .p-about-us-gallery__list-item__img {
  transform: scale(2.4);
  z-index: 10;
  transition: 0.6s;
}
.p-about-us-gallery .swiper-slide-active .p-about-us-gallery__list-item__img::after {
  display: none;
}
@media (max-width: 768px) {
  .p-about-us-gallery .swiper-slide-active .p-about-us-gallery__list-item__img {
    transform: scale(1);
  }
}
.p-about-us-gallery .swiper-slide-active .p-about-us-gallery__list-item__text {
  opacity: 1;
  transition: 0.3s;
}
.p-about-us-gallery .swiper-slide-active::after {
  display: none;
}
.p-about-us-gallery .swiper-slide-next {
  z-index: -10;
}

.c-modal {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s;
}

.c-modal-open {
  pointer-events: auto;
  opacity: 1;
  transition: 0.3s;
}

.c-modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.c-modal-close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.p-terms-of-use__content {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
.p-terms-of-use__content > * + * {
  margin-top: 2em;
}
.p-terms-of-use__content p:empty {
  height: 1.5em;
}
@media (max-width: 768px) {
  .p-terms-of-use__content {
    padding: 0 20px;
  }
}

.p-corporate-plans {
  padding-bottom: 100px;
}
.p-corporate-plans .p-service-single-nav {
  margin: 0;
}
.p-corporate-plans__wrap {
  max-width: 1088px;
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
}
.p-corporate-plans__detail {
  max-width: 1088px;
  width: 100%;
  margin: 0 auto 100px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
@media (max-width: 768px) {
  .p-corporate-plans__detail {
    margin: 0 auto 100px;
    padding: 0 20px;
  }
}
.p-corporate-plans__contact-button {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
  color: #f2eee7;
  text-align: center;
}
.p-corporate-plans__contact-button__link {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #f2eee7;
  width: clamp(335px, calc(720 / 1366 * 100vw), 720px);
  line-height: 56px;
  text-align: center;
}
@media (max-width: 768px) {
  .p-corporate-plans__contact-button__link {
    line-height: 41px;
  }
}
.p-corporate-plans__contact-button__inner {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.p-corporate-plans__contact-button__arrow {
  display: block;
  transition: all 0.3s;
}
.p-corporate-plans__contact-button__link:hover .p-corporate-plans__contact-button__arrow {
  transform: translateX(10px);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 150px;
}
@media (max-width: 768px) {
  html {
    scroll-padding-top: 60px;
  }
}

.p-faq summary::-webkit-details-marker {
  display: none;
}
.p-faq details.is-opened .p-faq__item-inner-head-icon svg {
  transform: rotate(0deg);
}
.p-faq .wp-block-lazyblock-faq {
  width: 100%;
}
.p-faq__content {
  max-width: calc(1088px + (44px * 2));
  margin: 0 auto;
  padding: 0 44px;
  display: flex;
  gap: clamp(40px, calc(96 / 1366 * 100vw), 96px);
}
@media (max-width: 768px) {
  .p-faq__content {
    padding: 0;
    flex-wrap: wrap;
  }
}
.p-faq__list {
  list-style: none;
  padding: 0;
}
.p-faq__nav {
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .p-faq__nav {
    width: 100%;
  }
}
.p-faq__nav-list {
  list-style: none;
  padding: 0;
  position: sticky;
  top: 150px;
  left: 0;
  margin: 0;
}
@media (max-width: 768px) {
  .p-faq__nav-list {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    margin-bottom: 50px;
    padding: 0 20px;
  }
}
@media (max-width: 768px) {
  .p-faq__nav-list::-webkit-scrollbar {
    display: none;
  }
}
.p-faq__nav-item {
  margin-bottom: 16px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(25 / 13);
  color: #bfb9ae;
}
.p-faq__nav-item-link {
  text-decoration: none;
  color: #bfb9ae;
  position: relative;
  width: fit-content;
  padding-bottom: 3px;
}
.p-faq__nav-item-link::after {
  content: "";
  display: block;
  position: absolute;
  width: 0%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: #f2eee7;
  transition: width ease 0.25s;
}
.p-faq__nav-item-link:hover::after {
  width: 100%;
}
.p-faq__nav-item.is-active .p-faq__nav-item-link {
  color: #f2eee7;
}
.p-faq__nav-item.is-active .p-faq__nav-item-link::after {
  width: 100%;
}
@media (max-width: 768px) {
  .p-faq__nav-item {
    margin-bottom: 0;
  }
  .p-faq__nav-item:not(:first-child) {
    margin-left: 10px;
  }
}
.p-faq__cms {
  display: grid;
  gap: 80px;
  width: 100%;
}
@media (max-width: 768px) {
  .p-faq__cms {
    gap: 100px;
  }
}
.p-faq__right {
  width: 100%;
}
@media (max-width: 768px) {
  .p-faq__right {
    padding: 0 20px;
  }
}
.p-faq__item:first-child {
  border-top: 1px solid #b05e61;
}
.p-faq__item-sub-title {
  margin-bottom: 30px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 200;
  letter-spacing: 0.1em;
  line-height: calc(28 / 24);
  color: #f2eee7;
}
.p-faq__item-sub-title:not(:first-child) {
  margin-top: 80px;
}
.p-faq__item-inner {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem);
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: calc(28 / 14);
  color: #f2eee7;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b05e61;
  padding-bottom: 12px;
}
.p-faq__item-inner-a {
  line-height: 1.7;
}
.p-faq__item-inner-qa {
  margin-right: 28px;
}
@media (max-width: 768px) {
  .p-faq__item-inner-qa {
    margin-right: 10px;
  }
}
.p-faq__item-inner-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 20px 18px 20px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .p-faq__item-inner-head {
    padding: 30px 5px 18px 5px;
  }
}
.p-faq__item-inner-head-left {
  display: flex;
}
.p-faq__item-inner-head-icon {
  margin-left: 10px;
}
.p-faq__item-inner-head-icon svg {
  transform: rotate(180deg);
  transition: transform 0.4s;
}
.p-faq__item-inner-body {
  overflow: hidden;
  width: 95.07%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .p-faq__item-inner-body {
    margin: 0;
  }
}
.p-faq__item-inner-body-padding {
  padding-bottom: 18px;
  display: flex;
}
@media (max-width: 768px) {
  .p-faq__item-inner-body-padding {
    padding: 0 5px 18px 5px;
  }
}
.p-faq__item-inner-body-answer {
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
.p-faq__item-inner-body-answer a {
  color: #f2eee7;
}

.is-active {
  color: #f2eee7;
}

.p-404__content {
  max-width: calc(720px + (44px * 2));
  margin: 0 auto;
  padding: 0 44px;
}
@media (max-width: 768px) {
  .p-404__content {
    padding: 0 20px;
  }
}
.p-404__content-sub-title {
  margin-bottom: 50px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 24);
  color: #f2eee7;
}
.p-404__content-text {
  margin-bottom: 60px;
  font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
  font-size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem);
  font-weight: 400;
  letter-spacing: 0;
  line-height: calc(28 / 14);
  color: #f2eee7;
}
.p-404__button {
  width: 100%;
}