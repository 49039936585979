@use "global" as g;

:root {
  --leading-trim: calc((1em - 1lh) / 2);
}

.l-footer {
  @include g.reset-link;
  margin-top: auto;

  &-parallax {
    position: relative;
    width: 100%;
    margin: 0 auto;

    &__img {
      position: relative;
      max-width: clamp(244px, calc(352 / 1366 * 100vw), 352px);
      height: clamp(319px, calc(460 / 1366 * 100vw), 460px);
      margin: 0 auto;
      overflow: hidden;
      isolation: isolate;
      border-radius: 685px 681px 0 0;
      @include g.gray-filter;
    }

    &__logo {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: clamp(335px, calc(718 / 1366 * 100vw), 718px);
      width: 100%;
      margin: 0 auto;
      padding-bottom: 10px;
      @include g.hover;

      svg {
        width: 100%;
      }
    }
  }

  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;

    &__list {
      @include g.reset-ul;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 0px 30px;
      margin: 35px 0 30px 0;
      padding: 0;

      @include g.sp {
        max-width: 263px;
        width: 100%;
        margin: 30px auto 41px auto;
      }
    }

    &__list-item {
      &__link {
        @include g.font(
          2.4rem,
          $family: g.$font-en,
          $color: g.$c-white,
          $weight: 400,
          $letter: 0
        );
        @include g.hover;
      }
    }
  }

  &-info {
    @include g.reset-ul;
    @include g.section;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px 0;
    padding-top: 23px;
    padding-bottom: 24px;

    @include g.sp {
      flex-direction: column;
      justify-content: center;
    }

    &__inner {
      border-top: 1px solid #b05e61;
    }

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px 30px;

      @include g.sp {
        max-width: 280px;
        margin: 0 auto;
        gap: 10px 30px;
      }
    }

    &__sns {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 15px 30px;

      &-item {
        @include g.hover;
      }
    }

    &__list-item {
      &__link {
        text-decoration: none;

        @include g.font(
          clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem),
          $family: g.$font-ja,
          $color: g.$c-white,
          $weight: 500,
          $letter: 0
        );
        @include g.hover;
      }
    }
  }

  &-info-wrap {
    border-top: 1px solid #b05e61;

    @include g.sp {
      padding: 0 20px;
      border: none;
    }
  }

  &-bottom {
    container-type: inline-size;
    padding: 20px 0 30px 0;
    background: #4d1418;
  }

  &-bottom-wrap {
    @include g.section;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 30px;

    @container (max-width: 925px) {
      justify-content: center;
      align-items: center;
    }

    @include g.sp {
      flex-direction: column;
    }
  }

  &-logo {
    &__list {
      @include g.reset-ul;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: clamp(10px, calc(16 / 1366 * 100vw), 16px);

      @include g.sp {
        grid-template-columns: repeat(2, auto);
      }
    }

    &__list-item {
      max-width: 168px;
      width: 100%;

      @include g.hover;
    }
  }

  &-copy {
    text-align: center;

    @include g.font(
      1.2rem,
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.05em,
      $lineheight: normal
    );
  }
}
