@use "global" as g;

.p-contact-complete {
  &__content {
    max-width: calc(720px + (44px * 2));
    margin: 0 auto;
    padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  }

  &__content-sub-title {
    margin-bottom: 50px;
    @include g.font(
      $size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(28 / 24)
    );
  }

  &__content-text {
    margin-bottom: 30px;
    @include g.font(
      $size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(28 / 14)
    );
  }

  &__content-information {
    margin-bottom: 60px;
    @include g.font(
      $size: clamp(1rem, calc(12 / 1366 * 100vw), 1.2rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(21 / 12)
    );
  }

  &__button {
    width: 100%;
  }
}
