@use "global" as g;

.p-contact {
  &__content {
    max-width: calc(720px + (44px * 2));
    margin: 0 auto;
    padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  }

  &__text {
    @include g.font(
      $size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: calc(28 / 14)
    );
    margin-bottom: 56px;

    @include g.sp {
      margin-bottom: 80px;
    }
  }

  &__form {
    &-item {
      margin-bottom: 30px;

      @include g.sp {
        // margin-bottom: 60px;
      }

      select,
      input,
      textarea {
        background: #ad545a;
        border: none;
        border-radius: 5px;
        width: 100%;
        min-height: 47px;
        padding: 10px;
        @include g.font(16px, $color: g.$c-white);
      }

      select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }
      &-content--select .wpcf7-form-control-wrap {
        position: relative;
        display: block;

        &::before {
          content: "";
          background-image: url("../../img/common/arrow-1.png");
          position: absolute;
          display: inline-block;
          width: 20px;
          height: 10px;
          right: 20px;
          top: 50%;
          transform: translate(-50%, -50%);
          background-size: contain;
          background-repeat: no-repeat;
          color: #fff;
        }
      }
    }

    &-item-title {
      margin-bottom: 15px;
      @include g.font(
        $size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem),
        $color: g.$c-white,
        $weight: 400,
        $letter: 0,
        $lineheight: calc(25 / 14)
      );

      span {
        padding-left: 10px;
        @include g.font(
          $size: clamp(1.2rem, calc(12 / 1366 * 100vw), 1.2rem),
          $color: g.$c-white,
          $weight: 400,
          $letter: 0,
          $lineheight: calc(21 / 12)
        );
      }
    }

    &-agree-policy {
      @include g.font(
        $size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem),
        $color: g.$c-white,
        $weight: 400,
        $letter: 0,
        $lineheight: calc(25 / 14)
      );

      .wpcf7-list-item {
        margin-left: 0;
      }
      @include g.sp {
        text-align: center;
      }

      input {
        position: relative;
        width: 17px;
        height: 17px;
        background: #ad545a;
        border-radius: 3px;
        vertical-align: -5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;

        &:checked::before {
          position: absolute;
          top: 1px;
          left: 5px;
          transform: rotate(50deg);
          width: 7px;
          height: 11px;
          border-right: 1px solid g.$c-white;
          border-bottom: 1px solid g.$c-white;
          content: "";
        }
      }

      a {
        margin-left: 8px;
        text-decoration: underline;
        color: g.$c-white;
      }
    }

    &-submit {
      display: flex;
      justify-content: center;
      margin-top: 80px;

      @include g.sp {
        margin-top: 100px;
      }

      input {
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 20px 0;
        background: transparent;
        border: none;
        @include g.font($color: g.$c-white);
        font-size: 16px;

        @include g.sp {
          padding: 12px 0;
        }
      }
      p {
        display: block;
        width: 100%;
      }

      input[type="submit"]:disabled {
        color: currentColor;
      }

      .c-border-button__text {
        width: 100%;
      }

      .c-border-button__link {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: 50px;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 15px;
          transition: all ease 0.25s;
          background-image: url("../../img/common/button-arrow.svg");
          background-repeat: no-repeat;
          background-size: contain;

          @include g.sp {
            right: 40px;
          }
        }

        &:hover {
          @media (hover: hover) {
            &::after {
              margin-left: 60px;
            }
          }
        }
      }

      .c-border-button__inner {
        padding: 0;
      }

      .wpcf7-spinner {
        display: none;
      }
    }
  }
}
