@use "global" as g;

// アニメーションタイミング
$time: transform ease 0.6s;

.c-service-card {
  @include g.reset-link;

  &__heading {
    padding-left: 10px;
    margin-bottom: clamp(16px, calc(20 / 1366 * 100vw), 20px);

    @include g.sp {
      display: grid;
      gap: 8px;
    }
  }

  &__number {
    @include g.font(
      2rem,
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 300,
      $letter: 0.05em,
      $lineheight: normal
    );
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: clamp(16px, calc(40 / 1366 * 100vw), 40px);

    @include g.font(
      clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.05em,
      $lineheight: normal
    );
  }

  &__arrow {
    transition: $time;
  }

  &__img {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1.6 / 1;
    border-radius: 10px;
    overflow: hidden;
    @include g.img;
    @include g.gray-filter;

    img {
      transition: $time;
    }
  }

  @media (hover: hover) {
    &:hover {
      .c-service-card__img img {
        transform: scale(1.1);
        transition: $time;
      }

      .c-service-card__arrow {
        transform: translateX(10px);
        transition: $time;
      }
    }
  }
}
