@use "global" as g;
/*--------------------
サービスブロック
--------------------*/

// デフォルト
.l-block--service-default {
  @include g.section;
  margin: 100px auto;

  &__wrap {
    position: relative;
    padding-left: clamp(17px, calc(92 / 1366 * 100vw), 92px);

    @include g.sp {
      padding-left: 17px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50px;
      left: 25px;
      margin: auto;
      width: 1px;
      height: 100%;
      background: #b05e61;

      @include g.sp {
        top: 20px;
        left: 0;
      }
    }
  }

  &__inner {
    width: 100%;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    gap: clamp(40px, calc(50 / 1366 * 100vw), 50px);
    margin-top: -35px;

    @include g.sp {
      flex-direction: column-reverse;
      margin-top: 0px;
      padding-top: 30px;
    }
  }

  &__head {
    max-width: 628px;
    width: 100%;
  }

  &__title {
    // margin-top: 130px;

    @include g.font(
      clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    @include g.sp {
      margin-top: 0;
    }
  }

  &__heading {
    margin-top: 130px;

    @include g.font(
      clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    @include g.sp {
      margin-top: 0;
    }
  }

  &__text {
    margin: 30px 0;

    @include g.font(
      clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: clamp(22.75px, calc(28 / 1366 * 100vw), 28px)
    );
  }

  &__img {
    max-width: 318px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    aspect-ratio: 1 / 1.38;
    @include g.img;

    @include g.sp {
      max-width: none;
    }
  }

  // 他ブロック
  .l-block--service-info,
  .l-block--service-instagram {
    margin: 60px auto;
    padding: 0;
  }

  .l-block--service-button {
    margin: 0;
    padding: 0;
  }
}

// 施設情報
.l-block--service-info {
  @include g.section;
  margin: 100px auto;
  position: relative;

  &__wrap {
    border: 1px solid #b05e61;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    cursor: pointer;

    @include g.font(
      clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    @include g.sp {
      padding: 22px 17px;
    }
  }

  &__icon {
    display: block;
  }

  &__body {
    padding: 0 30px;
    height: 0px;
    opacity: 0;

    @include g.sp {
      padding: 0 17px;
    }
  }

  &__list {
    display: grid;
    gap: 20px 0;
    margin: 0;
    padding: 30px 0;
    border-top: 1px solid #b05e61;
    padding-top: 30px;

    @include g.sp {
      padding: 23px 0 30px 0;
    }
  }

  &__list-item {
    display: flex;
    gap: 50px;

    @include g.sp {
      flex-direction: column;
      gap: 0px;
    }

    &__title,
    &__text {
      @include g.font(
        clamp(1.4rem, calc(15 / 1366 * 100vw), 1.5rem),
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.05rem,
        $lineheight: clamp(24.5px, calc(26.25 / 1366 * 100vw), 26.25px)
      );
    }

    &__title {
      max-width: 80px;
      width: 100%;
      display: inline-block;
    }

    a {
      color: g.$c-white;
      // text-decoration: none;
      @include g.hover;
    }
  }
}

// 概要
.l-block--service-about {
  @include g.section;
  margin: 100px auto;
  position: relative;

  &__title {
    margin-bottom: 30px;

    @include g.font(
      clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @include g.sp {
      flex-direction: column;
    }
  }

  &__body {
    max-width: 536px;
    width: 100%;
    padding-top: 20px;

    @include g.sp {
      padding: 0;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    aspect-ratio: 1.6 / 1;
    overflow: hidden;

    @include g.img;
  }

  &__heading {
    margin-bottom: 30px;

    @include g.font(
      clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: clamp(27px, calc(36 / 1366 * 100vw), 36px)
    );
  }

  &__text {
    margin-bottom: 40px;

    @include g.font(
      clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: clamp(22.75px, calc(28 / 1366 * 100vw), 28px)
    );

    @include g.sp {
      margin-bottom: 0;
    }
  }
}

// 会員
.l-block--service-member {
  @include g.section;
  margin: 100px auto;

  &__inner {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid #bfb9ae;

    @include g.sp {
      display: block;
      flex-direction: column;
    }
  }

  &__box {
    width: 100%;
    height: 100%;
    padding: 30px 90px;

    &:nth-child(1) {
      border-right: 1px solid #bfb9ae;

      @include g.sp {
        border-bottom: 1px solid #bfb9ae;
      }
    }

    @include g.sp {
      padding: 30px 16px;
    }
  }

  &__text {
    max-width: 344px;
    width: 100%;
    display: flex;

    @include g.font(
      clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.03em,
      $lineheight: clamp(28px, calc(31.5 / 1366 * 100vw), 31.5px)
    );
  }
}

// 事前通知
.l-block--service-mail {
  @include g.section;
  margin: 100px auto;

  .c-button__text {
    letter-spacing: 0;
    font-size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem);
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: clamp(30px, calc(41 / 1366 * 100vw), 41px);
    padding-top: 45px;
    background: #4b0d10;

    @include g.sp {
      flex-direction: column;
      align-items: flex-start;
      padding: 60px 18px 36px 17px;
    }
  }

  &__body {
    padding-bottom: 30px;

    @include g.sp {
      padding: 0 8px;
      padding-bottom: 0;
    }
  }

  &__title {
    position: relative;
    z-index: 2;

    @include g.font(
      3rem,
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: 28px
    );
  }

  &__text {
    position: relative;
    z-index: 2;
    margin: 30px 0 23px 0;

    @include g.font(
      clamp(1.3rem, calc(15 / 1366 * 100vw), 1.5rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: clamp(19.5px, calc(26.25 / 1366 * 100vw), 26.25px)
    );
  }

  &__link {
    position: relative;
    z-index: 2;
    text-decoration: none;
    @include g.hover;

    @include g.font(
      1.6rem,
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: clamp(19.5px, calc(26.25 / 1366 * 100vw), 26.25px)
    );
  }

  &__img {
    position: relative;
    z-index: 2;
    position: relative;
    max-width: 536px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1.8 / 1;
    @include g.img;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.38;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    mix-blend-mode: hard-light;
    @include g.img;
  }
}

// 取引実績
.l-block--service-trading {
  @include g.section;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  &__title {
    @include g.font(
      2rem,
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    span {
      display: block;
      transform: translateX(20px);
    }
  }

  &__list {
    max-width: 904px;
    width: 100%;
    display: grid;
    gap: clamp(9px, calc(16 / 1366 * 100vw), 16px);
    grid-template-columns: repeat(5, 1fr);
    margin: 0;
    padding: 0;

    @include g.sp {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__list-item {
    aspect-ratio: 1/1;
    background: #fff;
    @include g.img;
  }
}

// 事例紹介
.l-block--service-case {
  @include g.section;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @include g.sp {
    flex-direction: column;
    gap: 0;
  }

  &__title {
    white-space: nowrap;
    margin-bottom: 30px;

    @include g.font(
      clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );
  }

  &__body {
    display: grid;
    gap: 20px;
    max-width: 904px;
    width: 100%;

    @include g.sp {
      gap: 50px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: clamp(20px, calc(40 / 1366 * 100vw), 40px);
    width: 100%;
    height: fit-content;

    @include g.sp {
      grid-template-columns: 1fr;
    }
  }

  &__inner {
    display: grid;
    gap: clamp(10px, calc(30 / 1366 * 100vw), 30px);
  }

  &__img {
    max-width: 352px;
    width: 100%;
    // height: auto;
    aspect-ratio: 1.5 / 1;
    overflow: hidden;
    @include g.img;

    @include g.sp {
      max-width: none;
    }
  }

  &__heading {
    @include g.font(
      clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: normal
    );

    @include g.sp {
      margin-top: 20px;
    }
  }

  &__text {
    @include g.font(
      1.4rem,
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: 24.5px
    );
  }
}

// 料金表
.l-block--service-price {
  @include g.section;
  display: flex;
  justify-content: space-between;

  @include g.sp {
    flex-direction: column;
    align-items: center;
  }

  &__label {
    margin-bottom: 30px;

    @include g.font(
      2rem,
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: normal
    );
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @include g.sp {
      flex-direction: column;
      align-items: center;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: clamp(75px, calc(126 / 1366 * 100vw), 126px)
      clamp(17px, calc(30 / 1366 * 100vw), 30px)
      clamp(35px, calc(80 / 1366 * 100vw), 80px)
      clamp(17px, calc(30 / 1366 * 100vw), 30px);
    text-align: center;
    border: 1px solid #bfb9ae;
    border-radius: 600px 600px 0 0;
    margin: 0 auto;

    @include g.sp {
      padding-left: 17px;
      padding-right: 17px;
    }
  }

  &__inner {
    width: clamp(235px, calc(331 / 1366 * 100vw), 331px);
  }

  &__heading {
    @include g.font(
      clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: normal
    );
  }

  &__main {
    @include g.font(
      4rem,
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    &--unit {
      margin-right: 8px;
    }

    &--month {
      margin-left: 5px;

      @include g.font(
        2.4rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }
  }

  &__title {
    margin: 15px 0 25px 0;

    @include g.font(
      clamp(4rem, calc(80 / 1366 * 100vw), 8rem),
      $family: g.$font-en,
      $color: #b05e61,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    @include g.sp {
      margin: 5px 0 25px 0;
    }
  }

  &__text {
    @include g.font(
      clamp(1.4rem, calc(18 / 1366 * 100vw), 1.8rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.03em,
      $lineheight: clamp(24.5px, calc(31.5 / 1366 * 100vw), 31.5px)
    );
  }
}

// インスタ
.l-block--service-instagram {
  @include g.section;
  display: grid;
  gap: 40px 31px;
  margin: 100px auto 100px auto;

  @include g.sp {
    gap: 15px;
  }

  &__inner {
    display: grid;
    max-width: 996px;
    gap: 40px 31px;
    margin: auto 0 auto auto;

    @include g.sp {
      gap: 15px;
    }
  }

  &__title {
    grid-column: 1/2;
    grid-row: 1/2;

    @include g.font(
      2rem,
      $family: g.$font-en !important,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.1em,
      $lineheight: normal
    );

    span {
      display: block;
      transform: translateX(20px);
    }
  }

  &-button {
    grid-column: 1/2;
    grid-row: 2/3;

    @include g.sp {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  &__content {
    max-width: 812px;
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/4;

    @include g.sp {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }

  .l-block--service-button {
    margin: 0;
    padding: 0;
  }

  #sbi_images {
    padding: 0 !important;
    margin: 0 !important;
    gap: clamp(10px, calc(25 / 1366 * 100vw), 25px) !important;
  }
}

//フロー
.l-block--service-flow {
  @include g.section;
  margin: 100px auto;

  &__title {
    position: relative;
    text-align: center;

    @include g.font(
      clamp(2rem, calc(30 / 1366 * 100vw), 3rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    span {
      position: relative;
      z-index: 1;
      background: #931f26;
      padding: 0 20px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 1px;
      background: #b05e61;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    // gap: clamp(0px, calc(46 / 1366 * 100vw), 46px);
    gap: 46px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    padding-bottom: 60px;
    border-bottom: 1px solid #b05e61;

    @include g.sp {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 46px;
    }
  }

  &__list-item {
    max-width: 180px;
    width: 100%;

    @include g.sp {
      max-width: 100%;
      text-align: center;
    }

    &__label {
      @include g.font(
        1.2rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0,
        $lineheight: normal
      );
    }

    &__title {
      margin: 8px 0 20px 0;
      @include g.font(
        2rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__text {
      @include g.font(
        1.3rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.03em,
        $lineheight: 19.5px
      );
    }
  }
}

// プランメニュー
.l-block--service-plan {
  @include g.section;
  margin: 100px auto;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;

  &__title {
    @include g.font(
      clamp(2rem, calc(30 / 1366 * 100vw), 3rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 300,
      $letter: 0,
      $lineheight: normal
    );
  }

  &__body {
    max-width: 812px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @include g.sp {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 11px;
    }
  }

  &__img {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    @include g.img;
  }

  &__heading {
    margin-top: clamp(10px, calc(20 / 1366 * 100vw), 20px);

    @include g.font(
      clamp(1.4rem, calc(18 / 1366 * 100vw), 1.8rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.05em,
      $lineheight: normal
    );
  }

  &__inner {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
  }

  &__item,
  &__text {
    @include g.font(
      clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.05em,
      $lineheight: normal
    );
  }
}

// ボタン
.l-block--service-button {
  max-width: 720px;
  width: 100%;
  margin: 100px auto;
  padding: 0 20px;
}

/*--------------------
company plan
--------------------*/
// プラン
.l-block--corporate-plans-default {
  @include g.section;
  margin: clamp(80px, calc(100 / 1366 * 100vw), 100px) auto;
  width: 100%;

  &__wrap {
    position: relative;
    padding-left: clamp(17px, calc(92 / 1366 * 100vw), 92px);
    padding-bottom: 30px;

    @include g.sp {
      padding-left: 17px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50px;
      left: 30px;
      margin: auto;
      width: 1px;
      height: calc(100% - 50px);
      background: #b05e61;

      @include g.sp {
        left: 0;
        top: -10px;
        height: calc(100% + 10px);
      }
    }
  }

  &__title {
    font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
    font-size: clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    color: #f2eee7;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    gap: clamp(40px, calc(50 / 1366 * 100vw), 50px);
    flex-direction: row-reverse;
    margin-top: -38px;

    @include g.sp {
      display: block;
      margin-top: 30px;
    }
  }

  &__img {
    width: 318px;
    height: 318px;

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;

      @include g.sp {
        max-width: none;
        width: 100%;
      }
    }
  }

  &__head {
    flex: 1;
  }

  &__heading {
    margin-top: clamp(40px, calc(110 / 1366 * 100vw), 110px);
    font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
    font-size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    color: #f2eee7;
  }
  &__text {
    margin: 30px 0;
    font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
    font-size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem);
    font-weight: 400;
    letter-spacing: 0;
    line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
    color: #f2eee7;
  }
  &__plan {
    display: flex;
    border: 1px solid #f2eee7;

    @include g.sp {
      display: block;
    }

    &__items {
      font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
      font-size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem);
      font-weight: 400;
      letter-spacing: 0.03rem;
      color: #f2eee7;
      margin: 0;
      padding: 25px 40px 25px 30px;
      flex: 1;

      @include g.sp {
        padding: 20px 16px;
      }
    }
    &__left {
      border-right: 1px solid #f2eee7;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0.6;
      padding: 8px;

      @include g.sp {
        border-right: 0px;
        border-bottom: 1px solid #f2eee7;
        padding-top: 25px;
        padding-bottom: 20px;
        align-items: center;
        width: 100%;
      }
    }
    &__price {
      font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
      font-size: 4rem;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: calc((1em - 1lh) / 3.5);
      color: #f2eee7;
      white-space: nowrap;
    }
    &__year {
      font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
      font-size: 2.4rem;
      font-weight: 400;
      letter-spacing: 0;
      line-height: clamp(22.75px, calc(28 / 1366 * 100vw), 28px);
      color: #f2eee7;
      margin-left: 3px;
    }
    &__update {
      font-family: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
      font-size: clamp(1.5rem, calc(15 / 1366 * 100vw), 1.5rem);
      font-weight: 400;
      letter-spacing: 0;
      line-height: clamp(22.75px, 100%, 28px);
      color: #f2eee7;
      text-align: center;
    }
  }
  &__plan:nth-child(n + 1) {
    margin-top: 30px;

    @include g.sp {
      margin-top: 40px;
    }
  }
  &__plan:nth-child(n + 3) {
    margin-top: 20px;
  }
}
