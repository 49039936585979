@use "global" as g;

.p-news-archive {
  &__content {
    max-width: calc(1088px + (44px * 2));
    padding: 0 44px;
    margin: 0 auto;

    @include g.sp {
      padding: 0;
    }
  }

  &__row {
    display: flex;
    gap: clamp(30px, calc(96 / 1366 * 100vw), 96px);

    @include g.sp {
      flex-wrap: wrap;
    }
  }

  &__nav {
    flex-shrink: 0;

    @include g.sp {
      width: 100%;
    }

    &-list {
      list-style: none;
      padding: 0;
      position: sticky;
      top: 150px;
      left: 0;
      margin: 0;

      @include g.sp {
        white-space: nowrap;
        overflow-x: auto;
        display: flex;
        margin-bottom: 50px;
        padding: 0 30px;
      }
    }

    &-list::-webkit-scrollbar {
      @include g.sp {
        display: none;
      }
    }

    &-item {
      $item: &;

      margin-bottom: 16px;
      @include g.font(
        $size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem),
        $color: g.$c-gray,
        $weight: 400,
        $letter: 0,
        $lineheight: calc(25 / 13)
      );

      &-link {
        text-decoration: none;
        color: g.$c-gray;
        position: relative;
        width: fit-content;
        padding-bottom: 3px;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 0%;
          height: 1px;
          left: 0;
          bottom: 0;
          background: g.$c-white;
          transition: width ease 0.25s;
        }

        &:hover::after {
          width: 100%;
        }
      }

      &.is-active {
        #{$item}-link {
          color: g.$c-white;
          &::after {
            width: 100%;
          }
        }
      }
      @include g.sp {
        margin-bottom: 0;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 0 60px;

    @include g.sp {
      padding: 0 20px;
      gap: 20px;
    }
  }

  &__item {
    $item: &;

    &-link {
      display: flex;
      text-decoration: none;
      gap: clamp(10px, calc(18 / 1366 * 100vw), 18px);
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 15px;
        bottom: 30px;
        right: 20px;
        transition: all ease 0.25s;
        background-image: url("../../img/common/button-arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transition: transform ease 0.25s;

        @include g.sp {
          bottom: 20px;
          right: 10px;
        }
      }

      &:hover {
        @media (hover: hover) {
          &::after {
            transform: translateX(10px);
          }

          #{$item}-thumbnail img {
            transform: scale(1.2);
            transition: 0.45s;
          }

          #{$item}-inner::after {
            transform: scaleX(100%);
          }
        }
      }
    }

    &-thumbnail {
      width: 150px;
      height: 150px;
      flex-shrink: 0;
      overflow: hidden;

      @include g.sp {
        width: 110px;
        height: 110px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.45s;
      }
    }

    &-inner {
      width: 100%;
      padding: 30px 32px 45px;
      border-bottom: 1px solid #b05e61;
      position: relative;

      @include g.sp {
        padding: 0 32px 32px 5px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        transform: scaleX(0);
        background: g.$c-white;
        transition: transform ease 0.25s;
        transform-origin: 0 0;
      }
    }

    &-date {
      display: inline-block;
      margin-right: 15px;
      @include g.font(
        $size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem),
        $family: g.$font-en,
        $color: g.$c-gray,
        $weight: 400,
        $letter: 0,
        $lineheight: calc(22 / 18)
      );
    }

    &-category {
      display: inline-block;

      @include g.sp {
        display: block;
      }

      &-item {
        display: inline-block;
        @include g.font(
          $size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem),
          $color: g.$c-gray,
          $weight: 400,
          $letter: 0,
          $lineheight: calc(28 / 13)
        );

        @include g.sp {
          display: block;

          @include g.font(
            $size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem),
            $color: g.$c-gray,
            $weight: 400,
            $letter: 0,
            $lineheight: 1
          );
        }
      }
    }

    &-title {
      max-width: 495px;
      width: 100%;
      margin-top: 16px;
      @include g.font($color: g.$c-white);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}
