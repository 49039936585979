@use "global" as g;

html {
  scroll-behavior: smooth;
  scroll-padding-top: 150px;

  @include g.sp {
    scroll-padding-top: 60px;
  }
}

.p-faq {
  summary::-webkit-details-marker {
    display: none;
  }

  details.is-opened .p-faq__item-inner-head-icon svg {
    transform: rotate(0deg);
  }

  .wp-block-lazyblock-faq {
    width: 100%;
  }

  &__content {
    max-width: calc(1088px + (44px * 2));
    margin: 0 auto;
    padding: 0 44px;
    display: flex;
    gap: clamp(40px, calc(96 / 1366 * 100vw), 96px);

    @include g.sp {
      padding: 0;
      flex-wrap: wrap;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__nav {
    flex-shrink: 0;

    @include g.sp {
      width: 100%;
    }

    &-list {
      list-style: none;
      padding: 0;
      position: sticky;
      top: 150px;
      left: 0;
      margin: 0;

      @include g.sp {
        white-space: nowrap;
        overflow-x: auto;
        display: flex;
        margin-bottom: 50px;
        padding: 0 20px;
      }
    }
    &-list::-webkit-scrollbar {
      @include g.sp {
        display: none;
      }
    }

    &-item {
      $item: &;

      margin-bottom: 16px;
      @include g.font(
        $size: clamp(1.2rem, calc(13 / 1366 * 100vw), 1.3rem),
        $color: g.$c-gray,
        $weight: 400,
        $letter: 0,
        $lineheight: calc(25 / 13)
      );

      &-link {
        text-decoration: none;
        color: g.$c-gray;
        position: relative;
        width: fit-content;
        padding-bottom: 3px;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 0%;
          height: 1px;
          left: 0;
          bottom: 0;
          background: g.$c-white;
          transition: width ease 0.25s;
        }

        &:hover::after {
          width: 100%;
        }
      }

      &.is-active {
        #{$item}-link {
          color: g.$c-white;
          &::after {
            width: 100%;
          }
        }
      }

      @include g.sp {
        margin-bottom: 0;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }

  &__cms {
    display: grid;
    gap: 80px;
    width: 100%;

    @include g.sp {
      gap: 100px;
    }
  }

  &__right {
    width: 100%;

    @include g.sp {
      padding: 0 20px;
    }
  }

  &__item {
    &:first-child {
      border-top: 1px solid #b05e61;
    }

    &-sub-title {
      margin-bottom: 30px;
      @include g.font(
        $size: clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem),
        $color: g.$c-white,
        $weight: 200,
        $letter: 0.1em,
        $lineheight: calc(28 / 24)
      );
      &:not(:first-child) {
        margin-top: 80px;
      }
    }

    &-inner {
      @include g.font(
        $size: clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem),
        $color: g.$c-white,
        $weight: 300,
        $letter: 0.1em,
        $lineheight: calc(28 / 14)
      );

      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #b05e61;
      padding-bottom: 12px;

      &-a {
        line-height: 1.7;
      }

      &-qa {
        margin-right: 28px;

        @include g.sp {
          margin-right: 10px;
        }
      }

      &-head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 30px 20px 18px 20px;
        cursor: pointer;

        @include g.sp {
          padding: 30px 5px 18px 5px;
        }

        &-left {
          display: flex;
        }

        &-icon {
          margin-left: 10px;
        }
        &-icon svg {
          transform: rotate(180deg);
          transition: transform 0.4s;
        }
      }

      &-body {
        overflow: hidden;
        width: 95.07%;
        margin: 0 auto;

        @include g.sp {
          margin: 0;
        }

        &-padding {
          padding-bottom: 18px;
          display: flex;

          @include g.sp {
            padding: 0 5px 18px 5px;
          }
        }

        &-answer {
          @include g.font(
            $size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem),
            $color: g.$c-white,
            $weight: 400,
            $letter: 0,
            $lineheight: calc(28 / 14)
          );

          a {
            color: g.$c-white;
          }
        }
      }
    }
  }
}

.is-active {
  color: g.$c-white;
}
