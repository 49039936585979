@use "variable" as g;

/*--------------------
breakpoint
--------------------*/
@mixin sp {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 1024px) {
    @content;
  }
}

.sp-only {
  display: inline;
  @include pc {
    display: none;
    width: 100px;
  }
}
.pc-only {
  display: inline;
  @include sp {
    display: none;
  }
}

/*--------------------
font
--------------------*/
@mixin font(
  $size: clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem),
  $family: g.$font-ja,
  $color: g.$c-black,
  $weight: 400,
  $letter: 0,
  $lineheight: calc(28 / 16)
) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  letter-spacing: $letter;
  line-height: $lineheight;
  color: $color;
}

/*--------------------
style
--------------------*/

// セクション
@mixin section {
  max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
  margin: 0 auto;
  padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
}

// 記事画像中央揃え
@mixin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// 3行以上で...にする
@mixin ellipsis {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

// アニメーションスピード
@mixin transition {
  transition: 0.3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
}

/*--------------------
hover
--------------------*/
@mixin hover {
  transition: 0.5s;
  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }
}

@mixin img {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin gray-filter {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.48;
  }
}

@mixin reset-ul {
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@mixin reset-link {
  a {
    color: g.$c-white;
    text-decoration: none;
  }
}
