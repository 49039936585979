@use "global" as g;

.p-404 {
  &__content {
    max-width: calc(720px + (44px * 2));
    margin: 0 auto;
    padding: 0 44px;

    @include g.sp {
      padding: 0 20px;
    }
  }
  &__content-sub-title {
    margin-bottom: 50px;
    @include g.font(
      $size: clamp(1.8rem, calc(24 / 1366 * 100vw), 2.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(28 / 24)
    );
  }
  &__content-text {
    margin-bottom: 60px;
    @include g.font(
      $size: clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(28 / 14)
    );
  }
  &__button {
    width: 100%;
  }
}
