@use "global" as g;

.c-border-button {
  width: 100%;

  &__inner {
    border: 1px solid g.$c-white;

    &::before,
    &::after,
    & span::before,
    & span::after {
      background-color: g.$c-gray;
    }
  }

  &__text {
    color: g.$c-white;
  }

  &__link {
    display: block;
    width: 100%;
    position: relative;
    text-decoration: none;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 0 18px;

    @include g.sp {
      padding: 16px 0 15px;
    }

    /* animations */
    &::before {
      content: "";
      position: absolute;
      transition: all 0.1s;
      width: 1px;
      height: 0;
      top: 0;
      left: 0;
      transition-delay: 0.4s;
    }
    &::after {
      content: "";
      position: absolute;
      transition: all 0.1s;
      width: 0;
      height: 1px;
      bottom: 0;
      left: 0;
      transition-delay: 0.3s;
    }
  }

  &__text {
    @include g.font($color: g.$c-white);

    @include g.sp {
    }

    &::before {
      content: "";
      position: absolute;
      transition: all 0.1s;
      width: 1px;
      height: 0;
      bottom: 0;
      right: 0;
      transition-delay: 0.2s;
    }
    &::after {
      content: "";
      position: absolute;
      transition: all 0.1s;
      height: 1px;
      width: 0;
      top: 0;
      right: 0;
      transition-delay: 0.1s;
    }
  }

  &__arrow {
    display: block;
    line-height: 1;
    transition: transform ease 0.25s;
  }
}

// hover
@media (hover: hover) {
  .c-border-button__link:hover {
    .c-border-button__inner::before {
      height: 100%;
      transition: all 0.1s;
    }
    .c-border-button__inner::after {
      width: 100%;
      transition: all 0.1s;
      transition-delay: 0.1s;
    }
    .c-border-button__text::before {
      height: 100%;
      transition: all 0.1s;
      transition-delay: 0.2s;
    }
    .c-border-button__text::after {
      width: 100%;
      transition: all 0.1s;
      transition-delay: 0.3s;
    }

    .c-border-button__arrow {
      transform: translateX(10px);
    }
  }
}
