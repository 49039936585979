@use "global" as g;

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

.c-loading {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100vh;
  background: #931f26;
  transition: 0.6s;
  z-index: 9999;

  &__circle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    border: 1px solid #f2eee7;
    border-radius: 100px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 92%;
      height: 104%;
      background: #931f26;
      //   background: tomato;
      filter: blur(1px);
      border-radius: 80px;
      animation: loading infinite 0.6s linear;
    }
  }
}

.c-loading--close {
  opacity: 0;
  pointer-events: none;
  transition: 0.6s;
}
