@use "global" as g;

.p-privacy-policy {
  &__content {
    @include g.section;

    @include g.font(
      $size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(28 / 14)
    );

    & > * + * {
      margin-top: 2em;
    }

    p:empty {
      height: 1.5em;
    }

    a {
      color: g.$c-white;
    }

    @include g.sp {
      padding: 0 20px;
    }
  }
}
