@use "global" as g;

.p-service {
  &__wrap {
    max-width: calc(1088px + (clamp(20px, calc(44 / 1366 * 100vw), 44px) * 2));
    margin: 0 auto;
    padding: 0 clamp(20px, calc(44 / 1366 * 100vw), 44px);
  }

  &__content {
    max-width: 928px;
    margin: 0 0 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 40px;
    padding-top: 80px;

    @include g.sp {
      gap: 30px;
      grid-template-columns: auto;
      margin: 0 auto;
      padding-top: 0;
    }

    .c-service-card:nth-child(odd) {
      margin-top: -60px;

      @include g.sp {
        margin-top: 0;
      }
    }
  }
}
