@use "global" as g;

.c-common-texture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../img/common/common-texture.png");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: 99999;
  pointer-events: none;
}
