@use "global" as g;

.p-about-us {
  &-content {
    @include g.section;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 80px;

    &__text {
      @include g.font(
        clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem),
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: calc(clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem) * 2)
      );
    }

    &__img {
      max-width: 590px;
      width: 100%;
    }
  }

  &-history {
    width: 100%;
    height: auto;
    margin-top: clamp(200px, calc(220 / 1366 * 100vw), 220px);

    &__top {
      position: relative;
      position: sticky;
      top: 0;
      width: 100%;
      min-height: 438px;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 600px 600px 0 0;

      @include g.sp {
        width: 190px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.85);
      }
    }

    &__body {
      position: relative;
      width: 100%;
      height: 50vh;
      z-index: 100;
      padding-bottom: 140px;

      @include g.sp {
        height: auto;
      }
    }

    &__body-inner {
      position: absolute;
      bottom: 140px;
      left: 140px;

      @include g.sp {
        position: relative;
        bottom: 0;
        left: 0;

        padding: 0 20px;
      }
    }

    &__label {
      @include g.font(
        2.4rem,
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__title {
      margin: 40px 0 50px 0;

      @include g.font(
        2rem,
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__text {
      max-width: 720px;

      @include g.font(
        1.4rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: clamp(24.5px, calc(28 / 1366 * 100vw), 28px)
      );
    }
  }

  &-concept {
    @include g.section;
    @include g.reset-ul;
    position: relative;
    height: 740px;
    margin-top: 120px;

    @include g.sp {
      height: 937px;
    }

    &__title {
      @include g.font(
        2.4rem,
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__left {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;

      display: flex;
      justify-content: center;
      max-width: 315px;
      width: 100%;
      opacity: 0.85;
      padding-top: 50px;
      border-radius: 600px 600px 0 0;
      background: #62191e;

      @include g.sp {
        min-height: 937px;
        margin-left: -90px;
      }
    }

    &__list {
      width: clamp(375px, 100%, 760px);
      position: absolute;
      right: 0;
      bottom: 0;
      margin: auto;
      display: grid;
      gap: 35px;

      @include g.sp {
        padding: 0 20px !important;
        bottom: 70px;
      }
    }

    &__list-item {
      // padding: 0 16px clamp(30px, calc(35 / 1366 * 100vw), 35px) 16px;
      padding: 0 16px 35px 16px;
      border-bottom: 1px solid #b05e61;

      &__label {
        @include g.font(
          clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem),
          $family: g.$font-en,
          $color: #c3797d,
          $weight: 400,
          $letter: 0.1em,
          $lineheight: normal
        );
      }

      &__title {
        margin: 14px 0 25px 0;

        @include g.font(
          clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem),
          $family: g.$font-ja,
          $color: #f2eee7,
          $weight: 400,
          $letter: 0.1em,
          $lineheight: normal
        );
      }

      &__text {
        @include g.font(
          clamp(1.2rem, calc(14 / 1366 * 100vw), 1.4rem),
          $family: g.$font-ja,
          $color: #f2eee7,
          $weight: 400,
          $letter: 0,
          $lineheight: clamp(21px, calc(28 / 1366 * 100vw), 28px)
        );
      }
    }
  }

  &-mission-vision {
    @include g.section;
    margin-top: 178px;

    @include g.sp {
      margin-top: 78px;
    }

    &__body {
      padding-left: 40px;

      @include g.sp {
        padding-left: 90px;
      }
    }

    &__header {
      display: flex;

      &:nth-child(2) {
        margin-top: 30px;
        transform: translateX(140px);

        @include g.sp {
          margin-top: 40px;
          transform: translateX(0);
        }
      }

      @include g.sp {
        flex-direction: column;
      }
    }

    &__title {
      margin-bottom: 20px;
      @include g.font(
        clamp(3.6rem, calc(80 / 1366 * 100vw), 8rem),
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__label {
      @include g.font(
        clamp(1.5rem, calc(18 / 1366 * 100vw), 1.8rem),
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__ja {
      margin: 10px 0 5px 0;
      @include g.font(
        clamp(2.4rem, calc(40 / 1366 * 100vw), 4rem),
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0,
        $lineheight: normal
      );

      @include g.sp {
        margin: 10px 0;
      }
    }

    &__en {
      @include g.font(
        clamp(1.8rem, calc(20 / 1366 * 100vw), 2rem),
        $family: g.$font-en,
        $color: #c3797d,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__img {
      margin-top: 60px;
    }
  }

  &-access {
    border-top: 1px solid #b05e61;
    border-bottom: 1px solid #b05e61;
    margin-top: 100px;
    padding-top: 18px;
    padding-bottom: 18px;

    @include g.sp {
      margin-top: 80px;
      padding: 0 20px;
      border-top: none;
      border-bottom: none;
    }

    &__inner {
      @include g.section;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 40px;

      @include g.sp {
        flex-direction: column;
        justify-content: center;
        padding: 50px 0;
        border-top: 1px solid #b05e61;
        border-bottom: 1px solid #b05e61;
      }
    }

    &__info {
      @include g.sp {
        padding: 0 16px;
      }
    }

    &__logo {
      max-width: 227px;
      width: 100%;
      margin-bottom: 50px;

      @include g.sp {
        margin: 0 auto 50px auto;
      }
    }

    &__address,
    &__text {
      @include g.font(
        1.4rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.03em,
        $lineheight: 24.5px
      );
    }

    &__address {
      display: flex;

      margin-top: 18px;

      a {
        color: g.$c-white;
        text-decoration: none;
      }
    }

    &__icon {
      width: fit-content;
      margin-right: 10px;
    }

    &__text {
      margin-top: clamp(23px, calc(44 / 1366 * 100vw), 30px);
    }

    &__map {
      max-width: 536px;
      width: 100%;
      iframe {
        filter: grayscale(1);
        min-height: 368px;
      }
    }
  }

  &-map {
    @include g.section;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: clamp(80px, calc(130 / 1366 * 100vw), 130px);
    margin-bottom: clamp(80px, calc(120 / 1366 * 100vw), 120px);

    @include g.sp {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    &__heading {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-bottom: 10px;
    }

    &__item {
      width: 100%;

      &:hover {
        .p-about-us-map__icon {
          opacity: 0.5;
          transition: 0.3s;
        }
      }
    }

    &__title {
      white-space: nowrap;
      @include g.font(
        1.8rem,
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__border {
      width: 100%;
      height: 1px;
      background: #b05e61;
    }

    &__icon {
      cursor: pointer;
      transition: 0.3s;
    }

    &__img {
      cursor: pointer;
    }
  }

  &-gallery {
    @include g.section;
    position: relative;
    width: 100%;
    height: 100%;
    height: 530px;

    @include g.sp {
      padding-left: 0;
      padding-right: 0;
    }

    &__swiper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      transform: translateY(-45px);

      @include g.sp {
        transform: translateY(-25px);
      }
    }

    &__title {
      @include g.font(
        2.4rem,
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );

      @include g.sp {
        padding: 0 20px;
      }
    }

    &__list-item {
      &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
      }

      &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: fit-content;
        @include g.gray-filter;

        img {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 100%;
          height: auto;
          object-fit: cover;
          transition: 0.3s;
        }
      }

      &__text {
        @include g.font(
          clamp(1.6rem, calc(18 / 1366 * 100vw), 1.8rem),
          $family: g.$font-ja,
          $color: g.$c-white,
          $weight: 400,
          $letter: 0.1em,
          $lineheight: normal
        );

        position: absolute;
        right: 0;
        bottom: -50px;
        left: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        opacity: 0;
        transition: 0.3s;

        @include g.sp {
          bottom: 60px;
        }
      }
    }

    // swiper滑らかに
    .gallery-swiper {
      transition-timing-function: linear !important;
    }

    // ページネーション
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      width: 32px;
      height: 32px;
      bottom: 15px;
      margin: auto 0;
      z-index: 10;

      @include g.sp {
        display: none;
      }
    }

    .swiper-button-next {
      right: 25%;
    }
    .swiper-button-prev {
      left: 25%;
    }

    // アクティブ設定
    .swiper-slide {
      transform: scale(0.8);
      transition: 0.6s;

      @include g.sp {
        transform: scale(1);
      }
    }

    .swiper-slide-active {
      width: 100%;
      height: auto;
      transition: 0.7s;

      .p-about-us-gallery__list-item__img {
        transform: scale(2.4);
        z-index: 10;
        transition: 0.6s;

        &::after {
          display: none;
        }

        @include g.sp {
          transform: scale(1);
        }
      }

      .p-about-us-gallery__list-item__text {
        opacity: 1;
        transition: 0.3s;
      }

      &::after {
        display: none;
      }
    }

    .swiper-slide-next {
      z-index: -10;
    }
  }
}

.c-modal {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s;
}

.c-modal-open {
  pointer-events: auto;
  opacity: 1;
  transition: 0.3s;
}

.c-modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.c-modal-close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}
