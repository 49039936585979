@use "global" as g;

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: g.$c-primary;
  @include g.font();
  font-family: g.$font-ja;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

img {
  width: 100%;
  vertical-align: bottom;
}
