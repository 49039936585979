@use "global" as g;

.scroll {
  display: none;
  position: fixed !important;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: calc(100svh - 60px);
  overflow: scroll;
  z-index: 1000;
  border: 1px solid transparent;
  z-index: 10000;
  scroll-bar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-inner {
    width: 100%;
    height: 1000vh;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

body {
  overflow-x: hidden;
  scroll-bar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.p-top {
  position: relative;

  &-cover {
    position: relative;
    --bg-color: none;
    --z-index: 1000;
    --opacity: 1;
    transition: 0.6s;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: var(--bg-color);
      z-index: var(--z-index);
      opacity: var(--opacity);
      transition: 0.6s;
    }
  }

  &-fv {
    position: relative;
    width: 100%;
    height: 100svh;
    overflow: hidden;

    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: auto;
      height: 100svh;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100svh;
      opacity: 0.6;
      mix-blend-mode: hard-light;
      z-index: -10;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-mask {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100svh;
    }

    &__center {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: auto;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: clamp(302px, calc(568 / 1366 * 100vw), 568px);
      width: 100%;
      aspect-ratio: 1/1.25;
      margin: auto;
      border-radius: 600px 600px 0 0;
      overflow: hidden;
      isolation: isolate;
      @include g.gray-filter;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: clamp(400px, calc(750 / 1024 * 100vw), 750px);
      }

      @include g.sp {
        aspect-ratio: 1/1.67;
        max-width: clamp(302px, calc(568 / 786 * 100vw), 568px);
      }

      img {
        transition: transform 1.2s cubic-bezier(0.33, 1, 0.68, 1);
        max-width: none !important;
        width: 150%;
      }
    }

    &__title {
      position: absolute;
      right: 0;
      bottom: 71px;
      left: 0;
      max-width: 1160px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      animation: zoom 5s infinite ease;
      transform-origin: bottom bottom;
    }

    &__text {
      position: absolute;
      right: 0;
      bottom: 17px;
      left: 0;
      max-width: 670px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      animation: zoom 8s infinite ease;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 850px;
      }
    }
  }

  &-about {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: clamp(100px, calc(160 / 1366 * 100vw), 160px);
    max-width: calc(1253px + 20px * 2);
    height: clamp(369px, calc(732 / 1366 * 100vw), 732px);
    margin-top: 160px;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;

    @include g.sp {
      flex-direction: column;
      gap: 46px;
      padding: 0 20px;
      height: auto;
      margin-top: 60px;
    }

    &__img {
      position: relative;
      max-width: clamp(324px, calc(642 / 1366 * 100vw), 642px);
      width: 100%;
      height: clamp(369px, calc(732 / 1366 * 100vw), 732px);
      border-radius: 100% 100% 0 0;
      margin-left: clamp(-360px, calc(220 / -1366 * 100vw), -220px);
      overflow: hidden;
      isolation: isolate;
      @include g.gray-filter;

      @include g.sp {
        margin-left: -120px;
      }

      source,
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      max-width: 450px;
      height: fit-content;
    }

    &__title {
      @include g.font(
        2.4rem,
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__sub-title {
      position: relative;
      max-width: clamp(255px, calc(315 / 1366 * 100vw), 315px);
      width: 80%;
      margin: clamp(40px, calc(60 / 1366 * 100vw), 60px) 0;

      @include g.font(
        clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem),
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.05em,
        $lineheight: normal
      );

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: clamp(30px, calc(48 / 1366 * 100vw), 48px);
        height: 1px;
        background: g.$c-white;
      }
    }

    &__text {
      @include g.font(
        clamp(1.4rem, calc(15 / 1366 * 100vw), 1.5rem),
        $family: g.$font-ja,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.05em,
        $lineheight: calc(clamp(1.4rem, calc(15 / 1366 * 100vw), 1.5rem) * 1.75)
      );

      @include g.sp {
        letter-spacing: 0.04em;
      }
    }

    &__text--en {
      margin: 40px 0 clamp(50px, calc(60 / 1366 * 100vw), 60px) 0;

      @include g.font(
        1.4rem,
        $family: g.$font-en,
        $color: #bfb9ae,
        $weight: 300,
        $letter: 0,
        $lineheight: calc(1.4rem * 1.25)
      );
    }

    &__button {
      @include g.sp {
        width: fit-content;
        margin: auto 0 auto auto;
      }
    }
  }

  &-service {
    @include g.section;
    position: relative;
    margin-top: 160px;
    display: grid;
    grid-template-columns: 47px 1fr;
    gap: clamp(50px, calc(112 / 1366 * 100vw), 112px);

    @include g.sp {
      display: block;
    }

    &__left {
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: #b05e61;
      }

      &::before {
        top: 0;
        left: 0;

        @include g.sp {
          display: none;
        }
      }

      &::after {
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        max-height: 80%;
        height: 100%;

        @include g.sp {
          max-height: 1px;
          width: 85%;
          top: 150%;
          right: 0;
          bottom: 0;
          left: auto;
        }
      }
    }

    &__title {
      transform: rotate(-90deg) translateX(-60px);
      transform-origin: center center;

      @include g.font(
        3rem,
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );

      @include g.sp {
        width: fit-content;
        transform: rotate(-90deg) translateX(-100%);
        transform-origin: top left;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 20px 40px;
      padding-top: 80px;

      @include g.sp {
        gap: 30px;
        grid-template-columns: auto;
        padding-top: 120px;
      }

      .c-service-card:nth-child(odd) {
        margin-top: -60px;

        @include g.sp {
          margin-top: 0;
        }
      }
    }
  }

  &-line {
    @include g.section;
    margin-top: 120px;
    margin-bottom: 100px;
    @include g.hover;

    @include g.sp {
      margin: 80px 0;
    }
  }

  &-news {
    @include g.section;
    display: flex;
    gap: clamp(100px, calc(200 / 1366 * 100vw), 200px);

    @include g.sp {
      flex-direction: column;
      gap: 40px;
    }

    .p-news-archive__list {
      padding: 0;
    }

    &__title {
      @include g.font(
        clamp(2.4rem, calc(30 / 1366 * 100vw), 3rem),
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__content {
      width: 100%;
    }

    &__button {
      width: fit-content;
      margin: auto 0 auto auto;
    }
  }

  &-pr {
    padding: 96px 0 100px 0;
    background: url(../../img/front/pr-bg.jpg) no-repeat center / cover;

    @include g.sp {
      padding: 50px 20px 80px 20px;
    }

    &__inner {
      @include g.section;
    }

    &__content {
      width: fit-content;
      margin: auto 0 auto auto;

      @include g.sp {
        width: 100%;
      }
    }

    &__title {
      opacity: 0.45;
      margin-bottom: 65px;

      @include g.font(
        clamp(4rem, calc(80 / 1366 * 100vw), 8rem),
        $family: g.$font-en,
        $color: #f2eee7,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );

      @include g.sp {
        margin-bottom: 140px;
      }
    }

    &__label {
      @include g.font(
        clamp(2rem, calc(24 / 1366 * 100vw), 2.4rem),
        $family: g.$font-en,
        $color: #f2eee7,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__text {
      margin: 35px 0 40px 0;
      @include g.font(
        clamp(1.3rem, calc(15 / 1366 * 100vw), 1.5rem),
        $family: g.$font-en,
        $color: #f2eee7,
        $weight: 400,
        $letter: 0.1em,
        $lineheight: normal
      );
    }

    &__button {
      width: fit-content;
      margin: auto 0 auto auto;
    }
  }
}
