@use "global" as g;

.l-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  z-index: 999;

  @include g.sp {
    padding: 0 20px;
  }

  &__logo {
    @include g.hover;
  }

  // PC
  &__list-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  // PC nav
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    list-style: none;
    padding: 0;

    @include g.sp {
      display: none;
    }
  }

  &__list-item {
    &__link {
      text-decoration: none;

      @include g.font(
        clamp(1.4rem, calc(16 / 1366 * 100vw), 1.6rem),
        $family: g.$font-en,
        $color: g.$c-white,
        $weight: 400,
        $letter: 0.1em
      );
      @include g.hover;
    }
  }

  // SP nav
  &--sp {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: #931f26;
    opacity: 0;
    pointer-events: none;
    transition: 0.6s;
    z-index: -1;

    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      background: url(../../img/front/fv.jpg) no-repeat center / cover;
      opacity: 0.6;
      mix-blend-mode: hard-light;
      z-index: -1;
    }

    &__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include g.hover;

      @include g.sp {
        display: none;
      }
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: calc(940px + 20px * 2);
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding-top: 5%;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__list-wrap {
      max-width: 301px;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      background: #931f26;
      border-radius: 600px 600px 0 0;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include g.sp {
        padding-top: 140px;
        padding-bottom: 100px;
        margin: auto;
      }
    }

    &__list-top,
    &__list-bottom {
      padding: 0;
      margin: 0;
    }

    &__list-top {
      display: grid;
      gap: 30px;
    }

    &__list-top-item {
      text-align: center;

      &__link {
        text-decoration: none;

        @include g.font(
          2.4rem,
          $family: g.$font-en,
          $color: g.$c-white,
          $weight: 400,
          $letter: 0.1em
        );
        @include g.hover;
      }
    }

    &__list-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px 30px;
      margin-top: 120px;
    }

    &__list-bottom-item {
      &__link {
        text-decoration: none;

        @include g.font(
          1.2rem,
          $family: g.$font-en,
          $color: g.$c-white,
          $weight: 400,
          $letter: 0
        );
        @include g.hover;
      }
    }
  }

  // ハンバーガー
  &--hamburger {
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    @include g.hover;

    @include g.sp {
      justify-content: flex-end;
    }

    span {
      position: relative;
      display: block;
      width: 20px;
      height: 8px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        width: 20px;
        height: 1px;
        background: g.$c-white;
        transition: 0.3s;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }
}

.js-active-hamburger {
  span {
    &::before,
    &::after {
      transition: 0.3s;
    }
    &::before {
      transform: translate(0, 5px) rotate(20deg);
    }
    &::after {
      transform: translate(0, -2px) rotate(-20deg);
    }
  }
}

.js-active-sp-nav {
  opacity: 1;
  pointer-events: all;
  z-index: 1;
  transition: 0.6s;
}
