@use "global" as g;

.c-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  @include g.font(
    $size: clamp(1.6rem, calc(16 / 1366 * 100vw), 1.6rem),
    $color: g.$c-gray,
    $lineheight: calc(22 / 16)
  );

  @include g.sp {
    justify-content: center;
  }

  a {
    color: g.$c-gray;
    text-decoration: none;
  }

  .page-numbers {
    padding: 0 3px;
  }

  .current {
    color: g.$c-white;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: g.$c-white;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .next,
  .prev {
    display: none;
  }
}
