@use "global" as g;

.p-service-single {
  padding-bottom: 100px;

  &-nav {
    position: relative;
    width: 100%;
    border-top: 1px solid #b05e61;
    border-bottom: 1px solid #b05e61;
    padding: clamp(12px, calc(30 / 1366 * 100vw), 30px) 0;
    margin-top: -84px;
    z-index: 10;
    background: #931f26;

    @include g.sp {
      margin-top: 0;
    }

    &__list {
      @include g.section;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: clamp(20px, calc(60 / 1366 * 100vw), 60px);
      overflow-x: auto;
      margin: 0 auto !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__list-item {
      &__link {
        text-decoration: none;
        @include g.hover;

        @include g.font(
          clamp(1.2rem, calc(18 / 1366 * 100vw), 1.8rem),
          $family: g.$font-ja,
          $color: g.$c-white,
          $weight: 400,
          $letter: 0.05em,
          $lineheight: normal
        );
      }
    }
  }

  &-content {
    margin-top: clamp(80px, calc(120 / 1366 * 100vw), 120px);
  }

  .c-button__link {
    padding: 0;
  }
}

/*--------------------
service single head
--------------------*/
.l-service {
  padding-bottom: 200px;

  &__head {
    position: relative;
    padding: 0 44px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: clamp(180px, calc(286 / 1366 * 100vw), 286px);
      background-image: url("../../img/common/page-head.png");
      background-size: cover;
      opacity: 0.6;
      mix-blend-mode: hard-light;

      @media screen and (min-width: 950px) and (max-width: 1110px) {
        height: clamp(200px, calc(234 / 1110 * 100vw), 234px);
      }

      @media screen and (min-width: 769px) and (max-width: 950px) {
        height: clamp(0px, calc(200 / 950 * 100vw), 200px);
      }

      @include g.sp {
        height: clamp(243px, calc(353 / 770 * 100vw), 353px);
      }
    }

    @include g.sp {
      padding: 0 20px;
    }
  }

  &__head-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: clamp(20px, calc(30 / 1366 * 100vw), 30px);
    padding: clamp(80px, calc(190 / 1366 * 100vw), 190px) 0
      clamp(80px, calc(84 / 1366 * 100vw), 84px);
    margin: 0 auto;
    margin-block: calc((1em - 1lh) / 2);
    max-width: 1086px;
    width: 100%;

    @include g.sp {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__head-title-wrap {
    max-width: 536px;
    width: 100%;
  }

  &__head--img {
    position: relative;
    max-width: clamp(244px, calc(444 / 1366 * 100vw), 444px);
    width: 100%;
    height: clamp(301px, calc(547 / 1366 * 100vw), 547px);
    border-radius: 600px 600px 0 0;
    margin-top: -112px;
    overflow: hidden;
    @include g.img;
    bottom: 0;

    @include g.sp {
      margin-top: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.2;
    }
  }

  &__head--label {
    @include g.font(
      $size: clamp(1.8rem, calc(30 / 1366 * 100vw), 3rem),
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );
  }

  &__head--number {
    display: inline-block;
    transform: translateY(-13px) translateX(8px);

    @include g.font(
      $size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem),
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    @include g.sp {
      transform: translateY(-7px) translateX(5px);
    }
  }

  &__head--en {
    text-transform: capitalize;

    @include g.font(
      $size: clamp(4.6rem, calc(80 / 1366 * 100vw), 8rem),
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: normal
    );

    @include g.sp {
      margin: 10px 0;
    }
  }

  &__head--jp {
    @include g.font(
      $size: clamp(1.4rem, calc(20 / 1366 * 100vw), 2rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0.05em,
      $lineheight: normal
    );
  }

  &__head--text {
    min-height: 70px;
    margin: clamp(80px, calc(100 / 1366 * 100vw), 100px) 0;

    @include g.font(
      $size: clamp(1.3rem, calc(14 / 1366 * 100vw), 1.4rem),
      $family: g.$font-ja,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: clamp(26px, calc(28 / 1366 * 100vw), 28px)
    );

    @include g.sp {
      margin-bottom: 0;
      min-height: auto;
    }
  }
}
