@use "global" as g;

.c-breadcrumb {
  position: absolute;
  // top: clamp(70px, calc(120 / 1366 * 100vw), 120px);
  top: 80px;
  left: calc(100% - 44px);
  rotate: 90deg;
  width: calc(100vh - 120px);
  transform-origin: 0 0;
  text-transform: capitalize;

  @include g.sp {
    left: calc(100% - 20px);
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__current {
    width: 80px; /* 省略せずに表示するサイズを指定 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__current,
  &__link {
    margin: 0;
    @include g.font(
      $size: clamp(1rem, calc(13 / 1366 * 100vw), 1.3rem),
      $family: g.$font-en,
      $color: #91908e,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(16 / 13)
    );
  }

  &__line {
    margin: 0 6px;
    width: 10px;
    height: 1px;
    background: #656058;
  }

  &__link {
    display: inline-block;
    text-decoration: none;
  }

  &__current {
    max-width: 200px;
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
