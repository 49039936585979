@use "global" as g;

.l-main {
  padding-bottom: 200px;

  &__head {
    position: relative;
    padding: 0 44px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: clamp(180px, calc(286 / 1366 * 100vw), 286px);
      background-image: url("../../img/common/page-head.png");
      background-size: cover;
      opacity: 0.6;
      mix-blend-mode: hard-light;

      @media screen and (min-width: 950px) and (max-width: 1110px) {
        height: clamp(220px, calc(234 / 1110 * 100vw), 234px);
      }

      @media screen and (min-width: 770px) and (max-width: 950px) {
        height: clamp(205px, calc(220 / 950 * 100vw), 220px);
      }

      @include g.sp {
        height: clamp(180px, calc(200 / 770 * 100vw), 200px);
      }
    }

    @include g.sp {
      padding: 0 20px;
    }
  }

  &__head-title {
    position: relative;
    padding: clamp(133px, calc(155 / 1366 * 100vw), 155px) 0
      clamp(80px, calc(84 / 1366 * 100vw), 84px);
    margin: 0 auto;
    margin-block: calc((1em - 1lh) / 2);
    max-width: 1086px;
    width: 100%;
    text-transform: capitalize;

    @include g.font(
      $size: clamp(4.6rem, calc(120 / 1366 * 100vw), 12rem),
      $family: g.$font-en,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(200 / 120)
    );
  }
}
