// fonts
$font-ja: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho Pro", serif;
$font-en: "Cormorant Garamond", YuMincho, serif;

// colors
$c-black: #000;
$c-white: #f2eee7;
$c-gray: #bfb9ae;

$c-primary: #931f26;
$c-secondary: #4d1418;
