@use "global" as g;

.p-news-single {
  &__head {
    &::before {
      height: 200px;
    }
  }

  &__head-title {
    padding: 138px 0 100px;

    @include g.font(
      $size: 6rem,
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(100 / 60)
    );

    @include g.sp {
      padding-bottom: 55px;
    }
  }

  &__wrapper {
    max-width: calc(1088px + (44px * 2));
    margin: 0 auto;
    padding: 0 44px;
    display: flex;
    gap: 60px;

    @include g.sp {
      display: block;
      padding: 0 20px;
    }
  }

  &__thumbnail {
    width: clamp(278px, calc(308 / 1366 * 100vw), 308px);
    height: clamp(278px, calc(308 / 1366 * 100vw), 308px);
    aspect-ratio: 1 / 1;
    position: sticky;
    top: 150px;
    left: 0;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }

    @include g.sp {
      position: static;
    }
  }

  &__content {
    margin-bottom: 120px;

    @include g.sp {
      margin-top: 40px;
      margin-bottom: 100px;
    }
  }

  &__date {
    display: inline-block;
    margin-right: 15px;

    @include g.font(
      $size: clamp(1.8rem, calc(18 / 1366 * 100vw), 1.8rem),
      $family: g.$font-en,
      $color: g.$c-gray,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(22 / 18)
    );
  }

  &__category {
    display: inline-block;

    &-item {
      display: inline-block;

      @include g.font(
        $size: clamp(1.3rem, calc(13 / 1366 * 100vw), 1.3rem),
        $color: g.$c-gray,
        $weight: 400,
        $letter: 0,
        $lineheight: calc(28 / 13)
      );
    }
  }

  &__title {
    margin-top: 26px;
    padding-bottom: 46px;
    margin-bottom: 60px;
    border-bottom: 1px solid #b05e61;

    @include g.font(
      $size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: calc(10 / 1000 * 1em),
      $lineheight: calc(28 / 20)
    );

    @include g.sp {
      margin-top: 20px;
      padding-bottom: 50px;
      margin-bottom: 80px;
    }
  }

  &__content-body {
    @include g.font(
      $size: clamp(1.4rem, calc(14 / 1366 * 100vw), 1.4rem),
      $color: g.$c-white,
      $weight: 400,
      $letter: 0,
      $lineheight: calc(28 / 14)
    );

    p {
      margin-top: 50px;
    }

    p:empty {
      height: 1.5em;
      margin: 0;
    }

    a {
      color: g.$c-white;
      @include g.hover;
    }
  }

  &__back {
    &-btn {
      display: flex;
      justify-content: center;
      gap: 20px;
      text-decoration: none;

      @media (hover: hover) {
        &:hover {
          .p-news-single__back-icon {
            transform: translateX(-10px);
          }
        }
      }
    }

    &-icon {
      transition: transform ease 0.25s;
    }

    &-text {
      font-family: g.$font-en;
      @include g.font(
        $size: clamp(1.6rem, calc(20 / 1366 * 100vw), 2rem),
        $color: g.$c-white,
        $weight: 400,
        $letter: calc(200 / 1000 * 1em),
        $lineheight: calc(28 / 20)
      );
    }
  }
}
